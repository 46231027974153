import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import auth from '../auth/auth'
import { getUserRole } from '../constants/data';


class EditUserModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: this.props.user.id,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: '',
            status: '',
            resetPasswordChecked: false,
            resetPassword: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            user_id: nextProps?.user?.id,
            firstName: nextProps?.user?.firstName,
            lastName: nextProps?.user?.lastName,
            email: nextProps?.user?.email,
            phone: nextProps?.user?.phone,
            role: nextProps?.user?.role,
            status: nextProps?.user?.status,
        })
    }
    onSubmit(e) {
        e.preventDefault();
        if (this.state.firstName == '' || this.state.firstName == null) {
            alert("Please enter any First Name");
            return
        }
        if (this.state.lastName == '' || this.state.lastName == null) {
            alert("Please enter any Last Name");
            return
        }
        if (this.state.email == '' || this.state.email == null) {
            alert("Please enter email");
            return;
        }
        else {
            var inValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email);
            if (!inValidEmail) {
                alert("Please enter valid email");
            }
        }
        if (this.state.phone == '' || this.state.phone == null) {
            alert("Please Enter Phone Number");
            return
        }
        else {
            var value = this.state.phone;
            var phoneRe = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
            if (!phoneRe.test(value)) {
                alert("Please enter valid 10 digits Phone Number");
                return
            }
        }
        if (this.state.role == '' || this.state.role == null || this.state.role == 0) {
            alert("Please select role");
            return
        }
        if (this.state.resetPasswordChecked && this.state.resetPassword == '' || this.state.resetPassword == null) {
            alert("Please enter Password to reset");
            return
        }
        axios({
            method: 'post',
            url: ApiUrl() + '/user/edit.php',
            data: {
                firstName: this.state.firstName,
                id: this.state.user_id,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                role: this.state.role,
                status: this.state.status,
                resetPasswordChecked: this.state.resetPasswordChecked,
                resetPassword: this.state.resetPassword
            }
        }).then(function (res) {
            alert("Record updated successfully");
        });
    }
    onChange(e) {
        if (e.target.name == 'firstName') {
            this.setState({ firstName: e.target.value });
        }
        if (e.target.name == 'lastName') {
            this.setState({ lastName: e.target.value });
        }
        if (e.target.name == 'email') {
            this.setState({ email: e.target.value });
        }
        if (e.target.name == 'phone') {
            this.setState({ phone: e.target.value });
        }
        if (e.target.name == 'role') {
            this.setState({ role: e.target.value });
        }
        if (e.target.name == 'resetPassword') {
            this.setState({ resetPassword: e.target.value });
        }
        if (e.target.name == 'status') {
            this.setState({ status: e.target.value });
        }
    }
    onResetPasswordChecked = e => {
        this.setState({ resetPasswordChecked: e.currentTarget.checked });
    }
    render() {
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title> Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-color">
                <Form className="mt-2">
                    <Form.Group as={Row} controlId="user_id">
                        <Form.Label column sm={4} >
                            ID:
                                </Form.Label>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='user_id'
                                value={this.state.user_id}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <FormGroup as={Row} controlId="firstName">
                        <FormLabel column sm={4}>First Name: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='firstName'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.firstName}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="lastName" bsSize="small" >
                        <FormLabel column sm={4}>Last Name: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='lastName'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.lastName}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="phone" bsSize="large" >
                        <FormLabel column sm={4}>Phone: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='phone'
                                size="sm"
                                value={this.state.phone}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="email" bsSize="large" >
                        <FormLabel column sm={4}>Email: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='email'
                                size="sm"
                                value={this.state.email}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="role" bsSize="small" >
                        <FormLabel column sm={4}>Role: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="role" name='role' size="sm" onChange={(e) => this.onChange(e)}>
                                <option value="0">Select Role</option>
                                <option value={2} selected={this.state.role == 2 ? true : false}>Customer Service</option>
                                <option value={3} selected={this.state.role == 3 ? true : false}>Technician</option>
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="status" bsSize="small" >
                        <FormLabel column sm={4}>Status: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="status" name='status' size="sm" onChange={(e) => this.onChange(e)}>
                                <option value={0} selected={this.state.status == 0 ? true : false}>InActive</option>
                                <option value={1} selected={this.state.status == 1 ? true : false}>Active</option>
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="resetPasswordCheck" bsSize="small" >
                        <FormLabel column sm={4}>Reset Password: </FormLabel>
                        <Col sm={8}>
                            <input type="checkbox" onChange={this.onResetPasswordChecked} />
                        </Col>
                    </FormGroup>
                    {this.state.resetPasswordChecked &&
                        <FormGroup as={Row} controlId="resetPassword" bsSize="small" >
                            <FormLabel column sm={4}></FormLabel>
                            <Col sm={8}>
                                <FormControl
                                    name='resetPassword'
                                    size="sm"
                                    value={this.state.resetPassword}
                                    onChange={(e) => this.onChange(e)}
                                    type="password"
                                    placeholder="Enter New password"
                                />
                            </Col>
                        </FormGroup>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={(e) => this.onSubmit(e)}>Save </Button>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(EditUserModel);