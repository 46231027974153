import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faBars } from '@fortawesome/free-solid-svg-icons'

// import './App.css';
import './styles.css';
import Home from './components/home.js'
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import Planandpricing from './components/plansandpricing.js'
import Details from './components/details.js'
import Review from './components/review.js'
import Header from './components/header.js'
import Login from './components/login.js'
import Dashboard from './components/dashboard.js'
import SuperAdmin from './components/superadmin.js'
import ScrollToTop from './components/ScrollToTop.js'
import Logo from './images/stalwart.png';
import AboutUs from './components/aboutus'
import history from './components/history'
import TechnicianDashboard from './components/techniciandashboard.js'
import Thanks from './components/thanks'

import ThanksNew from './components/ThanksNew'
import { ProtectedRoute } from './protected/routes'
import auth from './auth/auth'

class App extends Component {

    toggleHamburger() {
        var x = document.getElementById("navbarResponsive");
        if (x.classList.contains("show")) {
            x.classList.remove("show");
           // x.className += " collapse show";
        } else {
            x.classList.add("show");

           // x.className = "navbar-collapse";
        }
    };

    render() {
        window.onscroll = function() {scrollFunction()};

        function scrollFunction() {
            //if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            //        document.getElementById("mainNav").style.backgroundColor = "white";
            //        document.getElementById("mainNav").style.height = "15%";
            //      document.getElementById("brandLogo").style.width= "100px";
                
            //} 
            //else {
            //        document.getElementById("mainNav").style.backgroundColor = "#ffffffa6";
            //        document.getElementById("mainNav").style.height = "20%";
            //        document.getElementById("brandLogo").style.width= "145px";

            //}

            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                document.getElementById("mainNav").classList.add("navbar-scrolled");
            } else {
                document.getElementById("mainNav").classList.remove("navbar-scrolled");
            }
        }


        return (
            <div className="App" >
                <Router history={history} basename="/">
                    <ScrollToTop>
                    <div id="link" className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
                                    <div className="container">
                                    <a className="navbar-brand js-scroll-trigger padding-0" href="/home" ><img id="brandLogo" src={Logo}/></a>
                                    <button className="navbar-toggler navbar-toggler-right" type="button" onClick={this.toggleHamburger} data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                                        <div className="collapse navbar-collapse" id="navbarResponsive">
                                            <ul className="navbar-nav ml-auto my-2 my-lg-0">
                                                <li className="nav-item menu-active"><Link to='/home' className="nav-link ">Home</Link></li>
                                                <li className="nav-item"><Link to='/plansandpricing' className="nav-link ">Pricing & Plans</Link></li>
                                                <li className="nav-item"><Link to='/aboutus' className="nav-link " href="#">About Us</Link></li>
                                                <li className="nav-item  nav-phone"><a className="nav-link " href="tel:7133143636"> <i className="fas fa-phone-alt"></i> (713) 314-3636</a></li>
                                            <li className="nav-item menu-get-start"><Link to='/plansandpricing' className="nav-link get-quote-btn-home" href="#">Get Started</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="banner-phone banner-phone-mob"><a href="tel:7133143636">  <i className="fas fa-phone-alt"></i>(713) 314-3636</a></div>
                                </nav>
                        </div>
                    <Switch>
                            <Route exact path='/shw_test' component={Home}>
                            <Home />
                        </Route>
                        <Route path='/home' component={Home} />
                        <Route path='/plansandpricing' component={Planandpricing} />
                        <Route path="/aboutus" component={AboutUs} />
                        <Route path="/logout" render={() => {
                            auth.logout()
                            return <Redirect to="/login" />
                        }} />
                        <Route path='/details' component={Details} />
                        <Route path='/review' component={Review} />
                        <Route path="/login" render={(props) => {
                            if (sessionStorage.ur == 3) {
                                return <Redirect to="techniciandashboard" />
                            } else if (sessionStorage.ur == 2) {
                                return <Redirect to="dashboard" />
                            }
                            else if (sessionStorage.ur == 1) {
                                return <Redirect to="superadmin" />
                            }
                            return <Login {...props} />
                        }} />
                        <ProtectedRoute path="/dashboard" component={Dashboard} />
                        <ProtectedRoute path="/superadmin" component={SuperAdmin} />
                        <ProtectedRoute path="/techniciandashboard" component={TechnicianDashboard} />
                            <Route path="/thanks" component={Thanks} originalComponent={Thanks} />
                            <Route path='/ThanksNew' component={ThanksNew} />

                        <Redirect to='/home' />
                    </Switch>
                    </ScrollToTop>
                </Router >
            </div >
        );
    }
}



export default App