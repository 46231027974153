import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AddCallModel from './AddCallModel';
import { ApiUrl } from '../constants/api';
import { Loader } from 'react-overlay-loader';
import EditCallTechnicianModel from './EditCallTechnicianModel';
import '../styles/dataTable.css';

class TechnicianDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calls: [],
            call: {},
            isOpen: false,
            loading: true,
        }
    }

    fetch_order_list() {
        var that = this;
        fetch(ApiUrl() + '/user/call.php?id=' + sessionStorage.uid)
            .then(response => response.json())
            .then(function (res) {
                if (!res.status) {
                    that.setState({ calls: null, errorMessage: res.message, loading: false });
                }
                else {
                    that.setState({ calls: res.data, loading: false, errorMessage: '' });
                }
            });
    }
    componentDidMount() {
        this.fetch_order_list();
    }
    openCallModal(c) {
        this.setState({
            isOpen: true,
            call: c
        });
    }
    closeCallModal = () => {
        this.setState({ isOpen: false });
        this.fetch_order_list();
    }

    formatAddress(address1, address2, city, state) {
        var address = address1;
        if (address2 != null && address2 != '' && address2 != 'null' && address != undefined)
            address = address + " , " + address2;
        if (city != null && city != '' && city != undefined)
            address = address + " , " + city;
        if (state != null && state != '' && state != undefined)
            address = address + " , " + state;
        return address;
    }
    render() {
        const calls = this.state.calls && this.state.calls.map((call, index) => {
            return {
                id: index + 1,
                shwId: call.shwId,
                description: call.description,
                created_on: call.created_on,
                package: call.package,
                status: <div onClick={() => this.openCallModal(call)}><a href='#' className='underline'>{call.status == 1 ? "Active" : "In-Active"}</a></div>,
                address: call.address,
                comment: call.comment

            }
        });

        let data = {
            columns: [
                {
                    label: 'ID/Workorder',
                    field: 'shwId',
                    sort: 'asc',
                    width: 200,
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                {
                    label: 'Description',
                    field: 'description',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'DateLogged',
                    field: 'created_on',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Package',
                    field: 'package',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Call Status',
                    field: 'status',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Covered Property Address',
                    field: 'address',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Comment',
                    field: 'comment',
                    sort: 'asc',
                    width: 200,
                }
            ],
            rows: calls || [],
        };

        return (
            <section class="login-header">
            <div className="font-color container">
                <div className="row mt-1">
                    <div className="col-sm-3 text-left mt-2 stal">Open Calls</div>
                    <div className="col-sm-8 text-right mt-2">Welcome {sessionStorage.email} </div>
                    <div className="col-sm-1 text-right mt-2">
                        <Link to='/logout'>Logout</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center active-cyan-4 mb-4 mt-3">
                        <MDBDataTable
                            bordered
                            responsive
                            hover
                            small
                            data={data}
                            maxHeight="200px"
                        />
                    </div>
                </div>
                <EditCallTechnicianModel
                    show={this.state.isOpen}
                    call={this.state.call}
                    close={this.closeCallModal}
                />
            </div>
            </section>
        );
    }
}
export default withRouter(TechnicianDashboard);