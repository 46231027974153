import React, { Component } from 'react';
// import '../styles/home.css';
import '../styles.css';

import axios from 'axios';
import Home from './home';
import Review from './review';
import Logo from '../images/StalwartLogo.jpeg'
import { getUsStates } from '../constants/data';

import { BrowserRouter as Router, withRouter, Route, Link, Switch, Redirect } from 'react-router-dom';

class Details extends Component {

    constructor(props) {
        super(props);
        this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
        this.onLastNameChanged = this.onLastNameChanged.bind(this);
        this.onZipChanged = this.onZipChanged.bind(this);
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onAddress1Changed = this.onAddress1Changed.bind(this);
        this.onAddress2Changed = this.onAddress2Changed.bind(this);
        this.onstreetAddressChanged = this.onstreetAddressChanged.bind(this);
        this.onpropertyZipChanged = this.onpropertyZipChanged.bind(this);
        this.onSameCurrentAddressChanged = this.onSameCurrentAddressChanged.bind(this);
        this.state = {
            plans: [],
            plan: '',
            firstName: '',
            lastName: '',
            zip: '',
            email: '',
            address1: '',
            address2: '',
            streetAddress: '',
            propertyZip: '',
            SameCurrentAddressChanged: '',
            propertycity: '',
            propertystate: '',
            streetAddress2: ''
        };
        
    }

    componentDidMount() {
        if (localStorage.hasOwnProperty("checkdata")) {
            const now = new Date()
            const itemStr = localStorage.getItem('checkdata');
            const itemget = JSON.parse(itemStr);
            // compare the expiry time of the item with the current time
            if (now.getTime() > itemget.expiry) {
                localStorage.clear();
                // alert('onw');
            }
            //
        }
        this.setState({
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            zip: localStorage.getItem("zip"),
            email: localStorage.getItem("email"),
            address1: localStorage.getItem("address1"),
            address2: localStorage.getItem("address2"),
            phonenumber: localStorage.getItem("phonenumber"),
            city: localStorage.getItem("city"),
            state: localStorage.getItem("state"),

            SameCurrentAddressChanged: localStorage.getItem("SameCurrentAddressChanged"),
            plan: localStorage.getItem("plan"),

            propertyZip: localStorage.getItem("propertyZip"),
            streetAddress: localStorage.getItem("streetAddress"),
            propertycity: localStorage.getItem("propertycity"),
            propertystate: localStorage.getItem("propertystate"),
            streetAddress2: localStorage.getItem("streetAddress2"),
        });
    }

    onFirstNameChanged = e => {
        this.setState({ firstName: e.currentTarget.value });
    }
    onLastNameChanged = e => {
        this.setState({ lastName: e.currentTarget.value });
    }
    onZipChanged = e => {

        this.setState({ zip: e.currentTarget.value });
    }
    onEmailChanged = e => {
        this.setState({ email: e.currentTarget.value });
    }
    onAddress1Changed = e => {
        this.setState({ address1: e.currentTarget.value });
        if (this.state.SameCurrentAddressChanged) {
            this.setState({ streetAddress: e.currentTarget.value });
        }
    }
    onAddress2Changed = e => {
        this.setState({ address2: e.currentTarget.value });
        if (this.state.SameCurrentAddressChanged)
        {
            this.setState({ streetAddress2: e.currentTarget.value });
        }
    }
    
    onpropertyZipChanged = e => {
        this.setState({ propertyZip: e.currentTarget.value });
        if (this.state.SameCurrentAddressChanged) {
            this.setState({ propertyZip: e.currentTarget.value });
        }
    }
    onSameCurrentAddressChanged = e => {
        if (e.target.checked == true) {
            this.setState({
                SameCurrentAddressChanged: e.currentTarget.value,
                streetAddress: this.state.address1,
                streetAddress2: this.state.address2,
                propertyZip: this.state.zip,
                propertycity: this.state.city,
                propertystate: this.state.state
            });
        }
        if (e.target.checked == false) {
            this.setState({
                SameCurrentAddressChanged: '',
                streetAddress: '',
                streetAddress2: '',
                propertyZip: '',
                propertycity: '',
                propertystate: ''
            });
        }

    }
    onPhoneChanged = e => {
        this.setState({ phonenumber: e.currentTarget.value });
    }
    onCityChanged = e => {
        this.setState({ city: e.currentTarget.value });
        if (this.state.SameCurrentAddressChanged) {
            this.setState({ propertycity: e.currentTarget.value });
        }
    }
    onStateChanged = e => {
        this.setState({ state: e.currentTarget.value });
        if (this.state.SameCurrentAddressChanged) {
            this.setState({ propertystate: e.currentTarget.value });
        }
    }
    
    onstreetAddressChanged = e => {
        this.setState({ streetAddress: e.currentTarget.value });
    }
    onpropertystreetAddress2Changed = e => {
        this.setState({ streetAddress2: e.currentTarget.value });

    }
    onpropertyCityChanged = e => {
        this.setState({ propertycity: e.currentTarget.value });

    }
    onpropertyStateChanged = e => {
        this.setState({ propertystate: e.currentTarget.value });
    }
    validdata() {

        var error = true;
        // alert(this.state.firstName);
        if (this.state.firstName == '' || this.state.firstName == null) {
            alert("Please Enter First Name");
            //debugger;
            error = false;
            return
        }
        if (this.state.lastName == '' || this.state.lastName == null) {
            alert("Please Enter Last Name");
            error = false;
            return
        }
        if (this.state.zip == '' || this.state.zip == null) {
            alert("Please Enter Zip Code");
            error = false;
            return
        }
        if (this.state.address1 == '' || this.state.address1 == null) {
            alert("Please Enter Address");
            error = false;
            return
        }
        if (this.state.phonenumber == '' || this.state.phonenumber == null) {
            alert("Please Enter Phone Number");
            error = false;
            return
        }
        else {
            var value = this.state.phonenumber;
            var phoneRe = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
            if (!phoneRe.test(value)) {
                alert("Please enter valid 10 digits Phone Number");
                error = false;
                return
            }
        }
        if (this.state.city == '' || this.state.city == null) {
            alert("Please Enter City")
            error = false;
            return
        }
        if (this.state.state == '' || this.state.state == null) {
            alert("Please Select State")
            error = false;
            return
        }
        if (this.state.propertystate == '' || this.state.propertystate == null) {
            alert("Please Select Property state")
            error = false;
            return
        }
        if (this.state.propertycity == '' || this.state.propertycity == null) {
            alert("Please Enter Property City")
            error = false;
            return
        }
        if (this.state.propertyZip == '' || this.state.propertyZip == null) {
            alert("Please Enter Property Zip Code");
            error = false;
            return
        }
        if (this.state.streetAddress == '' || this.state.streetAddress == null) {
            alert("Please Enter Property Address");
            error = false;
            return
        }
        if (this.state.email == '' || this.state.email == null) {
            alert("Please Enter Email")
            error = false;
            return
        }
        else {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
            }
            else {
                alert("Please Enter Valid Email")
                // alert('Please Enter Valid Email Address');
                error = false;
                return
            }
        }
        if (error == true) {
            localStorage.setItem("firstName", this.state.firstName);
            localStorage.setItem("lastName", this.state.lastName);
            localStorage.setItem("zip", this.state.zip);
            localStorage.setItem("email", this.state.email);
            localStorage.setItem("address1", this.state.address1);
            localStorage.setItem("address2", this.state.address2);
            localStorage.setItem("streetAddress", this.state.streetAddress);
            localStorage.setItem("phonenumber", this.state.phonenumber);
            localStorage.setItem("city", this.state.city);
            localStorage.setItem("state", this.state.state);
            localStorage.setItem("propertyZip", this.state.propertyZip);
            localStorage.setItem("SameCurrentAddressChanged", this.state.SameCurrentAddressChanged);
            localStorage.setItem("plan", this.props.location.plan);
            localStorage.setItem("propertycity", this.state.propertycity);
            localStorage.setItem("propertystate", this.state.propertystate);
            localStorage.setItem("streetAddress2", this.state.streetAddress2);
            const now = new Date();

            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: "expire",
                expiry: now.getTime() + 1000000,
            }
            localStorage.setItem('checkdata', JSON.stringify(item))
            this.props.history.push({
                pathname: '/review',
                state: {
                    plan: this.props.location.plan,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    zip: this.state.zip,
                    email: this.state.email,
                    address1: this.state.address1,
                    address2: this.state.address2,
                    streetAddress: this.state.streetAddress,
                    propertyZip: this.state.propertyZip,
                    phonenumber: this.state.phonenumber,
                    city: this.state.city,
                    state: this.state.state,
                    SameCurrentAddressChanged: this.state.SameCurrentAddressChanged,
                    propertycity: this.state.propertycity,
                    propertystate: this.state.propertystate,
                    streetAddress2: this.state.streetAddress2
                }
            })
        }
    }

    render() {
        return (
            <div classNames="container">
                <section class="price-sub-header inner-banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <h2>  Billing and Service Address </h2>
                                <p class="mt-3">
                                    <Link to='/home' className="breadcrumb-text-css">Home</Link>
                                    <span><i class="fas fa-angle-double-right"></i></span>
                                    <span> Billing and Service Address </span>
                                </p>
                            </div>
                            <div class="subbanner-phone banner-phone-web">
                                <a href="tel:7133143636">  
                                    <i class="fas fa-phone-alt"></i>(713) 314-3636</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 mt-5 mb-5">
                                <h2 class="text-center sub-h mb-3">Provides Billing and Service Address Details </h2>
                                <p class="text-black text-center">Service Address is the Property you are purchasing Stalwart Home Warranty for.</p>
                            </div>
                        </div>
                    </div>
                    <div class="container mb-5">
                        <form class="mh-form">  			
                            <div class="row">
                                <div class="col-lg-6 billing-detail-box border-right">
                                    <h3 class="text-black mb-3 border-bottom pb-4">Billing Address</h3>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" name="firstName" class="form-control input-text" id="" placeholder="First Name" value={this.state.firstName} onChange={this.onFirstNameChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Last Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text " id="" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.onLastNameChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputPassword" class="col-sm-3 col-form-label">Phone</label>
                                            <div class="col-sm-9">
                                                <input class="form-control input-text" id="" placeholder="Phone" name="phonenumber" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" value={this.state.phonenumber} onChange={this.onPhoneChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputPassword" class="col-sm-3 col-form-label">Email</label>
                                            <div class="col-sm-9">
                                                <input type="email" class="form-control input-text" id="" placeholder="Email" name="email" value={this.state.email} onChange={this.onEmailChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Address 1</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="Address 1" name="address1" value={this.state.address1} onChange={this.onAddress1Changed}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Address 2</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="Address 2" name="address2" value={this.state.address2} onChange={this.onAddress2Changed}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">City</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="City" name="city" value={this.state.city} onChange={this.onCityChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Select State</label>
                                            <div class="col-sm-9">
                                                <select name="State" id="State" className="input-text form-control" placeholder="Select State" onChange={this.onStateChanged}>
                                                    <option value="-1">Select State</option>
                                                    {getUsStates().map((v) =>
                                                        (
                                                            <option value={v} selected={v==this.state.state}>{v}</option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Zip Code</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="Zip Code" name="zip" value={this.state.zip} onChange={this.onZipChanged}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mt-3" id="confirm-buttons">
                                            <div class="form-group form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" onChange={this.onSameCurrentAddressChanged.bind(this)} /> Is the billing address same as the service address?
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 billing-detail-box">
                                        <h3 class="text-black mb-3 border-bottom pb-4">Service Address</h3>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Address 1</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="Address 1" name="streetAddress" value={this.state.streetAddress} onChange={this.onstreetAddressChanged} disabled={this.state.SameCurrentAddressChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Address 2</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="Address 2" name="streetAddress2" value={this.state.streetAddress2} onChange={this.onpropertystreetAddress2Changed} disabled={this.state.SameCurrentAddressChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">City</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" placeholder="City" name="propertycity" value={this.state.propertycity} onChange={this.onpropertyCityChanged} disabled={this.state.SameCurrentAddressChanged}/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Select State</label>
                                            <div class="col-sm-9">
                                                <select name="propertystate" id="propertystate" className="input-text form-control" placeholder="Select State" onChange={this.onpropertyStateChanged} disabled={this.state.SameCurrentAddressChanged}>
                                                    <option value="-1">Select State</option>
                                                    {getUsStates().map((v) =>
                                                        (
                                                            <option value={v} selected={v == this.state.propertystate}>{v}</option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="" class="col-sm-3 col-form-label">Zip Code</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control input-text" id="" name="propertyZip" value={this.state.propertyZip} onChange={this.onpropertyZipChanged} placeholder="Zip Code" disabled={this.state.SameCurrentAddressChanged}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-3" id="confirm-buttons">
                                        <div className="col-sm-12" onClick={this.validdata.bind(this)}>
                                            <a class=" get-quote-btn" href="#">Review And Pay</a>
                                        </div>
                                </div>
                         </form>
                    </div>
                </section>
                <footer class="footer-section">
                    <div class="container">
                        <div className="row" style={{justifyContent:'center'}}>
                            <div class="footer-col-1">
                                <h2 class="mt-0">Stalwart Home Warranty</h2>
                            </div>
                           
				        </div>
			            <hr style={{background:'#fff', marginBottom:'30px'}}/>
                        <div className="row">
                            <div class="col-lg-8 footer-col-3">
                                <h2 >Stalwart Home Warranty</h2>
                                <p style={{ marginTop: '-25px' }}>stalwarthomewarranty@gmail.com</p>
                                <p>© 2020 - Stalwart Home Warranty,<br /> All Right Reserved</p>
                            </div>
                            <div class="col-lg-4 ml-auto mb-5 mb-lg-0 footer-col-4">
                                <div class="media">
                                    <i class="fas fa-phone-alt fa-3x mb-3"></i>
                                    <div class="media-body pl-4">
                                        <h3>CALL US NOW !</h3>
                                        <h4>(713) 314-3636</h4>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </footer>
            </div >
        );
    }
}

export default withRouter(Details);