import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import auth from '../auth/auth'
import { getUserRole } from '../constants/data';


class AddNewUserModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: 0,
            password: '',
            confirmPassword: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: 0,
            password: '',
            confirmPassword: ''
        });
    }
    onSubmit(e) {
        e.preventDefault();
        if (this.state.firstName == '' || this.state.firstName == null) {
            alert("Please enter any First Name");
            return
        }
        if (this.state.lastName == '' || this.state.lastName == null) {
            alert("Please enter any Last Name");
            return
        }
        if (this.state.email == '' || this.state.email == null) {
            alert("Please enter email");
            return;
        }
        else {
            var inValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email);
            if (!inValidEmail) {
                alert("Please enter valid email");
            }
        }
        if (this.state.password == '' || this.state.password == null) {
            alert("Please enter password");
            return;
        }
        if (this.state.confirmPassword == '' || this.state.confirmPassword == null) {
            alert("Please enter password");
            return;
        }
        if (this.state.confirmPassword != this.state.password) {
            alert("Password does not match with Confirm Password");
            return;
        }
        if (this.state.phone == '' || this.state.phone == null) {
            alert("Please Enter Phone Number");
            return
        }
        else {
            var value = this.state.phone;
            var phoneRe = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
            if (!phoneRe.test(value)) {
                alert("Please enter valid 10 digits Phone Number");
                return
            }
        }
        if (this.state.role == '' || this.state.role == null || this.state.role == 0) {
            alert("Please select role");
            return
        }
        var that = this;
        axios({
            method: 'post',
            url: ApiUrl() + '/user/add.php',
            data: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                role: this.state.role,
                password: this.state.password,
            }
        }).then(function (res) {
            if (res.data.status) {
                alert("Record updated successfully");
                that.fetch_user_list();
            }
            else
                alert(res.data.message);
        });
    }
    onChange(e) {
        if (e.target.name == 'firstName') {
            this.setState({ firstName: e.target.value });
        }
        if (e.target.name == 'lastName') {
            this.setState({ lastName: e.target.value });
        }
        if (e.target.name == 'email') {
            this.setState({ email: e.target.value });
        }
        if (e.target.name == 'phone') {
            this.setState({ phone: e.target.value });
        }
        if (e.target.name == 'role') {
            this.setState({ role: e.target.value });
        }
        if (e.target.name == 'password') {
            this.setState({ password: e.target.value });
        }
        if (e.target.name == 'confirmPassword') {
            this.setState({ confirmPassword: e.target.value });
        }
    }
    render() {
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title> Add New User</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-color">
                <Form className="mt-2">
                   <FormGroup as={Row} controlId="firstName">
                        <FormLabel column sm={4}>First Name: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='firstName'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.firstName}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="lastName" bsSize="small" >
                        <FormLabel column sm={4}>Last Name: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='lastName'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.lastName}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="phone" bsSize="large" >
                        <FormLabel column sm={4}>Phone: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='phone'
                                size="sm"
                                value={this.state.phone}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="email" bsSize="large" >
                        <FormLabel column sm={4}>Email: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='email'
                                size="sm"
                                value={this.state.email}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="password" bsSize="large" >
                        <FormLabel column sm={4}>Password: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='password'
                                size="sm"
                                value={this.state.password}
                                onChange={(e) => this.onChange(e)}
                                type="password"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="confirmPassword" bsSize="large" >
                        <FormLabel column sm={4}>Confirm Password: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='confirmPassword'
                                size="sm"
                                value={this.state.confirmPassword}
                                onChange={(e) => this.onChange(e)}
                                type="password"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="role" bsSize="small" >
                        <FormLabel column sm={4}>Role: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="role" name='role' size="sm" onChange={(e) => this.onChange(e)}>
                                <option value={0} selected={this.state.role == 0 ? true : false}>Select Role</option>
                                <option value={2} selected={this.state.role == 2 ? true : false}>Customer Service</option>
                                <option value={3} selected={this.state.role == 3 ? true : false}>Technician</option>
                            </FormControl>      
                        </Col>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={(e)=>this.onSubmit(e)}>Save </Button>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(AddNewUserModel);