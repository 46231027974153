import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

class Header extends React.Component {
    render() {
        return (
            <div id="link" className="container-fluid">
                <div className="row bg-light">
                    <div className="col-sm-9">
                        <nav class="navbar navbar-expand-sm ">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <span class="nav-button"> <Link to='/home' className="nav-link menu font-color">Home</Link> </span>
                                </li>
                                <li class="nav-item">
                                    <span class="nav-button"> <Link to='/plansandpricing' className="nav-link menu font-color">Pricing & Plans </Link> </span>
                                </li>
                                <li class="nav-item">
                                    <span class="nav-button"> <Link to='/' className="nav-link menu font-color">About Us</Link> </span>
                                </li>
                                <li class="nav-item">
                                    <span class="nav-button"> <Link to='/Login' className="nav-link menu font-color">Login</Link> </span>
                                </li>
                                <li class="nav-item">
                                    <span class="nav-button"> <Link to='/Dashboard' className="nav-link menu font-color">Dashboard</Link> </span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-sm-3">
                        <div className="font-size-12 mt-3"><span className="header-span">Call Us for A Free Quote </span>
                            <span className="header-span-phone">713 314 3636</span></div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Header;