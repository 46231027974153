import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AddCallModel from './AddCallModel';
import AddEditModel from './AddEditModel';
import ClientsAndCalls from './ClientsAndCalls';
import { ApiUrl } from '../constants/api';
import { formatAddress, plansAndPriceUrl } from '../constants/data';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import '../styles/dataTable.css';
import auth from '../auth/auth'
import fetchHeaders from '../helpers/fetch';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: '',
            email: sessionStorage.email,
        }
    }
    render() {
        return (
            <section class="login-header">
            <div className="font-color container">
                <div className="row mt-1">
                    <div className="col-sm-11 text-right mt-2">Welcome {sessionStorage.email} </div>
                    <div className="col-sm-1 text-right">
                        <Link to='/logout'>Logout</Link>
                    </div>
                </div>
                <ClientsAndCalls/>
            </div>
            </section>
        );
    }
}
export default withRouter(Dashboard);