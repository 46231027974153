import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
// import '../styles/home.css';
import '../styles.css';
import Planandpricing from './plansandpricing';
import Logo from '../images/StalwartLogo.jpeg';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import Homecontent from '../components/home/homecontent';

class Home extends Component {
    handleClick() {
        window.open('/plansandpricing');
    }

    render() {
        return (
            <Homecontent/>
        );
    }
}


export default Home