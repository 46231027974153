import React, { Component } from 'react';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import '../styles/home.css';
import aboutusimage from '../images/aboutus.jpeg';
import Logo from '../images/StalwartLogo.jpeg'
import about from '../images/about1.png'

import { BrowserRouter as Router, Route, withRouter, Link, Switch, Redirect } from 'react-router-dom';
import { ApiUrl } from '../constants/api';

import axios from 'axios';



class Aboutus extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                <section class="price-sub-header inner-banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <h2> About us </h2>
                                <p class="mt-3">
                                    <Link to='/home' className="breadcrumb-text-css">Home</Link>
                                    <span><i class="fas fa-angle-double-right"></i></span>
                                    <span> About us </span>
                                </p>
                            </div>
                            <div class="subbanner-phone banner-phone-web"><a href="tel:7133143636">  <i class="fas fa-phone-alt"></i>(713) 314-3636</a></div>
                        </div>
                    </div>
                </section>
                <section class="">
                    <div class="container mt-5">
                        <div class="row">
                            <div class="col-lg-6 mt-5 mb-5">
                                <h2 class="text-left sub-h mb-3"> Stalwart Home Warranty </h2>
                                    <p class="text-black text-left">Stalwart Home Warranty LLC (SHW) is based in Pearland, Texas. The company was formed to provide home warranty coverage for Houston, its adjacent suburbs, and cities. <br /> <br />Our mission is to provide good, quality, and most importantly peace of mind to our customers. Being a locally-based company, SHW is able to provide quality service in a timely manner to our members. Therefore, our clients comfort is our business </p>
	               	        </div>
                                        <div class="col-lg-6">
                                            <div class="p-2 about-page">
                                    <img class="img-fluid" src={about} />

                                            </div>
                                        </div>
            	        </div>
        	        </div>
                                <div class="container mb-5">

                            </div>
        </section>
        <footer class="footer-section">
                    <div class="container">
                        <div className="row" style={{justifyContent:'center'}}>
                            <div class="footer-col-1">
                                <h2 class="mt-0">Stalwart Home Warranty</h2>
                            </div>
                           
				        </div>
			            <hr style={{background:'#fff', marginBottom:'30px'}}/>
                        <div className="row">
                            <div class="col-lg-8 footer-col-3">
                                <h2 >Stalwart Home Warranty</h2>
                                <p style={{ marginTop: '-25px' }}>stalwarthomewarranty@gmail.com</p>
                                <p>© 2020 - Stalwart Home Warranty,<br /> All Right Reserved</p>
                            </div>
                            <div class="col-lg-4 ml-auto mb-5 mb-lg-0 footer-col-4">
                                <div class="media">
                                    <i class="fas fa-phone-alt fa-3x mb-3"></i>
                                    <div class="media-body pl-4">
                                        <h3>CALL US NOW !</h3>
                                        <h4>(713) 314-3636</h4>

                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                
            </footer>
                        </div>
        );
    }
}
export default withRouter(Aboutus);