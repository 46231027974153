import React, { Component } from 'react';
import { Form, Row, Modal, Button } from "react-bootstrap";
import '../styles/home.css';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import { getPrice, getSCF, getAddon, getAddonPrice, calculateTax, formatAddress } from '../constants/data';

class Thanks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            zip: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            phonenumber: '',
            propertycity: '',
            propertystate: '',
            streetAddress: '',
            propertyZip: '',
            streetAddress2: '',
            total: 0.0,
            selectedPlan: '',
            addon: '',
            tax: 0.0,
            plan: ''
        }
    }
    componentDidMount() {
        this.setState({
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            zip: localStorage.getItem("zip"),
            email: localStorage.getItem("email"),
            address1: localStorage.getItem("address1"),
            address2: localStorage.getItem("address2"),
            phonenumber: localStorage.getItem("phonenumber"),
            city: localStorage.getItem("city"),
            state: localStorage.getItem("state"),
            propertyZip: localStorage.getItem("propertyZip"),
            streetAddress: localStorage.getItem("streetAddress"),
            streetAddress2: localStorage.getItem("streetAddress2"),
            propertycity: localStorage.getItem("propertycity"),
            propertystate: localStorage.getItem("propertystate"),
            total: localStorage.getItem("total"),
            selectedPlan: localStorage.getItem("selectedPlan"),
            addon: localStorage.getItem("addons"),
            tax: localStorage.getItem("tax"),
            plan: localStorage.getItem("plan"),
        });
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.close}>
                <Modal.Header className="text-center choose" closeButton>
                    <Modal.Title> Order Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="font-color">
                        <section class="">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 mt-5 mb-5">
                                        <h2 class="text-center sub-h mb-3"> Stalwart Home Warranty </h2>
                                        <p class="text-black text-center">Thank you for trusting us with your home ! </p>
                                    </div>
                                </div>
                            </div>
                            <div class="container mb-5">
                                <form class="mh-form">  			
                                    <div class="row">
                                                
                                        <div class="col-lg-8 billing-detail-box border-right">
                                            <h3 class="text-black mb-3 border-bottom pb-4">Order Details</h3>
                                            <div class="form-group row">
                                                <label for="" class="col-sm-4  text-black font14">Order Number</label>
                                                <div class="col-sm-8">
                                                    <span class="col-form-label">{this.props.shwId}</span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="" class="col-sm-4  text-black font14">Total Amount Paid</label>
                                                <div class="col-sm-8">
                                                    <span class="col-form-label">{this.state.total}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <h4 class="text-black mb-3 border-bottom pb-4"><small>Billing Address</small></h4>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Name</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">{this.state.firstName +  this.state.lastName }</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Email</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">{this.state.email}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Phone</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">{this.state.phonenumber}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Address</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">
                                                                {(this.state.address2 && this.state.address2 != null && 
                                                                this.state.address2 != 'null' ) ? this.state.address1 +","+ this.state.address2 +","+ this.state.city + ", " + this.state.state + ", " + this.state.zip : this.state.address1  +","+ this.state.city + ", " + this.state.state + ", " + this.state.zip}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 border-left">
                                                    <h4 class="text-black mb-3 border-bottom pb-4"><small>Services Address</small></h4>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Name</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">{this.state.firstName +  this.state.lastName }</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Email</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">{this.state.email}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Phone</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">{this.state.phonenumber}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="" class="col-lg-4  text-black font14">Address</label>
                                                        <div class="col-lg-8">
                                                            <span class="col-form-label">
                                                            {(this.state.streetAddress2 && this.state.streetAddress2 != null && 
                                                                this.state.streetAddress2 != 'null' ) ? this.state.streetAddress +","+ this.state.streetAddress2 +","+ this.state.propertycity + ", " + this.state.propertystate + ", " + this.state.propertyZip : this.state.streetAddress  +","+ this.state.propertycity + ", " + this.state.propertystate + ", " + this.state.propertyZip}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 billing-detail-box">
                                            <h3 class="text-black mb-3 border-bottom pb-4">Package Summary</h3>
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th class="bg-blue">Selected Plan + Additional coverage</th>
                                                        <th class="bg-blue">Price</th>
                                                    </tr>
                                                    {this.state.selectedPlan != '' && <tr>
                                                        <td>{this.state.plan}</td>
                                                        <td>${getPrice(this.state.plan)}</td>
                                                    </tr>}
                                                    {this.state.addon && this.state.addon.split(',').map((item, i) => {
                                                        return (<tr>
                                                            <td>{getAddon(item)}</td>
                                                            <td>${getAddonPrice(item)}</td>
                                                        </tr>)
                                                    })}
                                                    {this.state.tax != '' && <tr>
                                                        <td>Tax</td>
                                                        <td>${this.state.tax}</td>
                                                    </tr>}
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>${this.state.total}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-3 text-center">
                                        <p class="border p-2 text-danger">We have send copy this confirmation and additional infomation to the email you provided</p>
                                    </div>
                                </form>
                    </div>
                </section>
                 </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default withRouter(Thanks);