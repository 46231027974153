export function getPrice(getSelectedPlan) {
    switch (getSelectedPlan) {
        case 'standard':
            return 375;
        case 'gold':
            return 475;
        case 'platinum':
            return 650;
        default:
            return 0;
    }
}

export function getSCF(getSelectedPlan) {
    switch (getSelectedPlan) {
        case 'standard':
            return 75;
        case 'gold':
            return 70;
        case 'platinum':
            return 65;
        default:
            return 0;
    }
}
export function getAddonPrice(index) {
    switch (index) {
        case '1':
            return 25;
        case '2':
            return 180;
        case '3':
            return 90;
        case '4':
            return 50;
        case '5':
            return 100;
        case '6':
            return 75;
        case '7':
            return 120;
        case '8':
            return 95;
        case '9':
            return 100;
        case '10':
            return 75;
        case '11':
            return 75;
        default:
            return 0;
    }
}

export function getAddon(index) {
    switch (index) {
        case '1':
        case 1:
            return "Pre-Season HVAC Tune-up";
        case '2':
        case 2:
            return "Swimming Pool / Spa Equipment";
        case '3':
        case 3:
            return "Washer / Dryer(set)";
        case '4':
        case 4:
            return "Additional HVAC Units";
        case '5':
        case 5:
            return "Well Pump";
        case '6':
        case 6:
            return "Septic Tank Pumping / Septic System / Sewage Ejector Pump";
        case '7':
        case 7:
            return "Outdoor Kitchen";
        case '8':
        case 8:
            return "Limited Roof Leak Repair";
        case '9':
        case 9:
            return "Kitchen Refrigerator";
        case '10':
        case 10:
            return " Water softener / Reverse Osmosis/whole house filtration Booster Pump";
        case '11':
        case 11:
            return "Well Pump";
        default:
            return 0;
    }
}

export function calculateTax(total) {
    var totalAfterTax = total * 0.0825
    return parseFloat(totalAfterTax).toFixed(2);
}

export function paymentSecretKeyTest() {
    return "pk_test_51HHDbIEuCCJNtrQAZS0H17ATK6G06bliLaSXKRMI5W07YPIL6jZEcm5aRF0LL8BH0ctKXRXmhnY2lzGuJCI9UcQU005p5lC3sf";
}
export function paymentSecretKeyProd() {
    return "pk_live_51HHDbIEuCCJNtrQAEKtvLH5jVA4SpSMcvp43bjxqHWze1lMzscGG8K7tpM4U5fQmfkHaNJAfLmOEqm5s3SZBr2oT00sVkMZgTb";
}


export function homePageUrl() {
    return "/home";
}
export function plansAndPriceUrl() {
    return "/plansandpricing";
}
export function thanksPageUrl() {
    return "/thanksnew";
}

export function formatAddress(address1, address2, city, state) {
    var address = address1;
    if (address2 != null && address2 != 'null' && address2 != '' && address2 != undefined)
        address = address + " , " + address2;
    if (city != null && city != '' && city != undefined)
        address = address + " , " + city;
    if (state != null && state != '' && state != undefined)
        address = address + " , " + state;
    return address;
}

export function getUsStates() {
    var states = "AK,AL,AR,AZ,CA,CO,CT,DE,FL,GA,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VA,VT,WA,WI,WV,WY";
    return states.split(",");
}


export function getUserRole(index) {
    switch (index) {
        case '1':
            return "Super Admin";
        case '2':
            return "Customer Service";
        case '3':
            return "Technician";
    }
}

