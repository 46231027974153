import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import auth from '../auth/auth'

class EditCallModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client_id: this.props.user.id,
            comment: '',
            description: '',
            technician: '',
            call_id: '',
            call_status: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        debugger;
        this.setState({
            comment: nextProps?.call?.comment,
            description: nextProps?.call?.description,
            technician: nextProps?.call?.user_id,
            call_id: nextProps?.call?.id,
            call_status: nextProps?.call?.status,
        })
    }
    onSubmit(e) {
        e.preventDefault();
        if (this.state.description == '' || this.state.description == null) {
            alert("Please Enter Description");
            return
        }
        if (this.state.technician == '' || this.state.technician == null) {
            alert("Please Select Technician");
            return
        }
        axios({
            method: 'post',
            url: ApiUrl()+ '/call/update.php',
            data: {
                description: this.state.description,
                technician: this.state.technician,
                id: this.state.call_id,
                status: this.state.call_status
            }
        }).then(function (res) {
            if(res.token){
                auth.logout()
            }else{
                alert("Record updated successfully");
            }
        });
    }
    onChange(e) {
        if (e.target.name == 'description') {
            this.setState({ description: e.target.value });
        }
        
        if (e.target.name == 'technician') {
            this.setState({ technician: e.target.value });
        }
        if (e.target.name == 'status') {
            this.setState({ call_status: e.target.value });
        }
    }
    render() {
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title> Edit Call</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-color">
                <Form className="mt-2">
                    <Form.Group as={Row} controlId="callId">
                        <Form.Label column sm={4} >
                            Call ID:
                                </Form.Label>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props?.call?.id}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <FormGroup as={Row} controlId="dateLogged">
                        <FormLabel column sm={4}>Date Logged: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props?.call?.dateLogged}
                                disabled
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="propertyAddres" bsSize="small" >
                        <FormLabel column sm={4}>Service Address: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props?.call?.address}
                                disabled
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="description" bsSize="large" >
                        <FormLabel column sm={4}>Description: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='description'
                                size="sm"
                                value={this.state.description}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="comment" bsSize="large" >
                        <FormLabel column sm={4}>Technician Comment: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='comment'
                                size="sm"
                                disabled
                                value={this.state.comment}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="technician" bsSize="small" >
                        <FormLabel column sm={4}>Assign to Technician: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="technician" name='technician' size="sm" onChange={(e) => this.onChange(e)}>
                                <option value="0">Select Technician</option>
                                {this.props.technician && this.props.technician.map((t) => (
                                    <option value={t.id} selected={this.state.technician == t.id ? true : false}>{t.name}</option>
                                ))}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="status" bsSize="small" >
                        <FormLabel column sm={4}>Status: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="status" name='status' size="sm" onChange={(e) => this.onChange(e)}>
                                <option value="1" selected={this.state.call_status === "1" ? true : false}>Active</option>
                                <option value="0" selected={this.state.call_status === "0" ? true : false}>In-Active</option>
                            </FormControl>
                        </Col>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={(e)=>this.onSubmit(e)}>Save </Button>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(EditCallModel);