import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import EditCallModel from './EditCallModel';
import AddNewCallModel from './AddNewCallModel';
import { ApiUrl } from '../constants/api';
import { formatAddress } from '../constants/data';
import auth from '../auth/auth';
import fetchHeaders from '../helpers/fetch';

class AddCallModel extends Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.state = {
            openCalls: [],
            user: {},
            show: false ,
            properties: [],
            technician: [],
            isCallnewOpen: false,
            isCallEditOpen: false,
        }

    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            openCalls: nextProps.openCalls,
            show: nextProps.show,
            user: this.props.user
        })
    }
    openCallNewModel = () => this.setState({ isCallnewOpen: true, show: true  });
    closeCallNewModel = () => this.setState({ isCallnewOpen: false, show: true });
    //closeCallEditModel = () => this.setState({ isCallEditOpen: false, show: true });
    closeCallNewModel = () => {
        this.setState({ isCallnewOpen: false });
        fetch(ApiUrl() + '/call/list.php?id=' + this.state.user.id, {
            headers: fetchHeaders()
        })
            .then(response => response.json())
            .then(data => {
                if(data.token){
                    auth.logout()
                }else{
                    data.message && data.message.toLowerCase() == "no data found." ? this.setState({ openCalls: null }) : this.setState({ openCalls: data })
                }
            });

        //this.props.close();
    }
    closeCallEditModel = () => {
        this.setState({ isCallEditOpen: false, show: true });
        fetch(ApiUrl() + '/call/list.php?id=' + this.state.user.id, {
            headers:fetchHeaders()
        })
            .then(response => response.json())
            .then(data => data.message && data.message.toLowerCase() == "no data found." ? this.setState({ openCalls: null }) : this.setState({ openCalls: data }));

        //this.props.close();
    }
    fetch_technician() {
        fetch(ApiUrl() + '/user/technician.php', {
            headers:fetchHeaders()
        })
            .then(response => response.json())
            .then(data => {
                if(data.token){
                    auth.logout()
                }else{
                    data.status == false ? this.setState({ technician: null }) : this.setState({ technician: data.technician })
                }
                
            });
    }
    handleNewCallOpenPopup(u) {
        fetch(ApiUrl()+'/property/list.php?id=' + u.id, {
            headers:fetchHeaders()
        })
            .then(response => response.json())
            .then(data => {
                if(data.token){
                    auth.logout()
                }else{
                    data.message && data.message.toLowerCase() == "no data found." ? this.setState({ properties: null }) : this.setState({ properties: data })
                }
            });
        this.fetch_technician();
        this.setState({
            isCallnewOpen: true,
            user: u,
        });
    }
    handleEditcallOpenPopup(c, u) {
        this.fetch_technician();
        this.setState({
            isCallEditOpen: true,
            user: u,
            call: c,
        });
    }
    render() {
        const openCalls = this.state.openCalls && this.state.openCalls.map((call) =>
            (<tr>
                <td>{call.id}</td>
                <td>{call.description}</td>
                <td>{call.dateLogged}</td>
                <td>{call.package}</td>
                {call.status == 1 &&
                    <td className="text-center"
                        onClick={() => this.handleEditcallOpenPopup(call, this.state.user)}><a href='#' className='underline'>Active</a>
                    </td>
                }
                {call.status == 0 &&
                    <td className="text-center" onClick={() => this.handleEditcallOpenPopup(call, this.state.user)}>
                    <a href='#' className='underline'>InActive</a>
                </td>
                }
                <td> {formatAddress(call.address1, call.address2, call.city, call.state)}</td>
                <td>{call?.first_name + " "+ call?.lastname }</td>
                <td>{call.comment}</td>
            </tr>)
        );
        return <Modal show={this.state.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title> Client Info and Call History</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-color">
                <Form.Group as={Row} controlId="firstName">
                    <Form.Label column sm={4} className="label label-primary label-outlined">
                        <div className="label label-primary label-outlined"> Client</div>
                    </Form.Label>
                    <Form.Label column sm={4} >
                        Customer Id: {this.props.user.id}
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row}>
                    <table className="table table-bordered">
                        <thead className="choose">
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Billing Address</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.props.user.first_name}</td>
                                <td>{this.props.user.last_name}</td>
                                <td>{this.props.user.phone_number}</td>
                                <td>{this.props.user.email}</td>
                                <td>{formatAddress(this.props.user.address1, this.props.user.address2, this.props.user.city, this.props.user.state)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Form.Group>
                <Form.Group as={Row} controlId="firstName">
                    <Form.Label column sm={4} className="label label-primary label-outlined">
                        <div className="label label-primary label-outlined"> Calls</div>
                    </Form.Label>
                    <Button variant="primary" column sm={8} onClick={() => this.handleNewCallOpenPopup(this.props.user)} >
                        New Call
                        </Button>
                </Form.Group>
                <Form.Group as={Row}>
                    <table className="table table-bordered">
                        <thead className="choose">
                            <th> Call ID</th>
                            <th>Description</th>
                            <th>Date Logged</th>
                            <th> Package</th>
                            <th> Call Status</th>
                            <th> Covered Property</th>
                            <th> Technician</th>
                            <th> Technician Comments</th>
                        </thead>
                        <tbody>
                            {this.state.openCalls == null && <tr><td colspan='8'>No Open call exists.</td></tr>}
                            {this.state.openCalls && openCalls}
                        </tbody>
                    </table>

                    <EditCallModel show={this.state.isCallEditOpen} close={this.closeCallEditModel} user={this.state.user} call={this.state.call} technician={this.state.technician}/>
                    <AddNewCallModel show={this.state.isCallnewOpen} close={this.closeCallNewModel} user={this.state.user} properties={this.state.properties} technician={this.state.technician}/>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(AddCallModel);