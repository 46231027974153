import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
import axios from "axios";
import '../styles/home.css';
import Logo from '../images/StalwartLogo.jpeg';
import fn from '../images/furniture.jpeg';
import Planandpricing from './plansandpricing';
import additional from '../images/shwaddon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiUrl } from '../constants/api';
import { getPrice, getSCF, getAddon, getAddonPrice, calculateTax, formatAddress } from '../constants/data';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter as Router, useLocation, Route, Link, Switch, Redirect } from 'react-router-dom';
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { paymentSecretKeyProd, homePageUrl } from '../constants/data';
const stripePromise = loadStripe(paymentSecretKeyProd());


let thi;
class Review extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            zip: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            SameCurrentAddressChanged: '',
            plan: '',
            termAndCondition: false,
            phonenumber: '',
            propertycity: '',
            propertystate: '',
            streetAddress: '',
            propertyZip: '',
            streetAddress2: '',
            plan_type: '',
            addons: '',
            tax: 0.0
        }
        console.log(this.props);
        // this.state = {
        // plans: []
        // }
    }
    componentDidMount() {
        this.setState({
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            zip: localStorage.getItem("zip"),
            email: localStorage.getItem("email"),
            address1: localStorage.getItem("address1"),
            address2: localStorage.getItem("address2"),
            phonenumber: localStorage.getItem("phonenumber"),
            city: localStorage.getItem("city"),
            state: localStorage.getItem("state"),

            SameCurrentAddressChanged: localStorage.getItem("SameCurrentAddressChanged"),
            plan: localStorage.getItem("plan"),

            propertyZip: localStorage.getItem("propertyZip"),
            streetAddress: localStorage.getItem("streetAddress"),
            streetAddress2: localStorage.getItem("streetAddress2"),
            propertycity: localStorage.getItem("propertycity"),
            propertystate: localStorage.getItem("propertystate"),

            selectedPlan: localStorage.getItem("selectedPlan"),
            scf_price: localStorage.getItem("scf_price"),
            price: localStorage.getItem("total"),
            plan_type: localStorage.getItem("plan_type"),
            addons: localStorage.getItem("addons"),
            tax: localStorage.getItem("tax")
        });
    }
    onTermAndConditionChanegd = e => {
        this.setState({ termAndCondition: e.currentTarget.checked });
    }

    savedata() {
        axios({
            method: 'post',
            url: ApiUrl() + '/client/add.php',
            data: {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                zipcode: this.state.zip,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                phonenumber: this.state.phonenumber,
                state: this.state.state,
                plan_id: this.state.plan_type,
                price: this.state.price,
                scf_price: this.state.scf_price,
                total_price: this.state.price,
                propery_of_interest_address_same: this.state.propery_of_interest_address_same,
                poia_zipcode: this.state.propertyZip,
                poia_address: this.state.streetAddress,
                poia_city: this.state.propertycity,
                poia_state: this.state.propertystate,
                addons: this.state.addons
            }
        }).then(function (res) {
            window.localStorage.clear();
        });
    }
    render() {
        return (
            <div>
                    <section class="price-sub-header inner-banner">
                        <div class="container">
                            <div class="row">
                            <div class="col-lg-12">
                                <h2> Your Order Summary </h2>
                                <p class="mt-3">
                                    <Link to='/home' className="breadcrumb-text-css">Home</Link>
                                    <span><i class="fas fa-angle-double-right"></i></span>
                                    <span> Your Order Summary </span>
                                </p>
                            </div>
                            <div class="subbanner-phone banner-phone-web"><a href="tel:7133143636">  <i class="fas fa-phone-alt"></i>(713) 314-3636</a></div>
                            </div>
                        </div>
                    </section>
                    <section class="">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 mt-5 mb-5">
                                <h4 class="text-center sub-h mb-3">Thank you <b>{this.state.firstName + " " + this.state.lastName} </b> for Choosing SHW.</h4>
                                <h5 class="text-center mb-3">Below is a Summary of Your Selection</h5>
                                <p class="text-center">Please Review and make Payment</p>
                            </div>
                        </div>
                    </div>
                        <div class="container mb-5">
        		        <div id="cart" class="mh-form">  			
        				<div class="row">
                        <div class="col-lg-6">
                        <div class="row">
                        <div class="col-lg-6 px-0">
                              <h5 class="text-black mb-3 border-bottom pb-4 text-left">Service Address</h5>
                              <table class="table table-borderless">
                                 <tbody>
                                    <tr>
                                       <th scope="row"><strong>First Name</strong></th>
                                       <td className="data_padding">{this.state.firstName}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><strong>Last Name</strong></th>
                                      <td className="data_padding">{this.state.lastName}</td>
                                    </tr>
                                     <tr>
                                      <th scope="row"><strong>Email</strong></th>
                                      <td className="data_padding">{this.state.email}</td>
                                    </tr>
                                     <tr>
                                      <th scope="row"><strong>Phone</strong></th>
                                      <td className="data_padding">{this.state.phonenumber}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><strong>Address</strong></th>
                                      <td className="data_padding">{(this.state.streetAddress2 !== null && this.state.streetAddress2 !== 'null' &&
                                        this.state.streetAddress2 != '') ? this.state.streetAddress + "," + this.state.streetAddress2 + "," +this.state.propertycity + ", " + this.state.propertystate : this.state.streetAddress + "," +this.state.propertycity + ", " + this.state.propertystate}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><strong>Zip Code</strong></th>
                                      <td className="data_padding">{this.state.propertyZip}</td>
                                    </tr>
                                 </tbody>
                              </table>
                            </div>

                            <div class="col-lg-6 px-0">
                              <h5 class="text-black mb-3 border-bottom pb-4 text-left">Billing Address</h5>
                              <table class="table table-borderless">
                                 <tbody>
                                    <tr>
                                       <th scope="row"><strong>First Name</strong></th>
                                       <td className="data_padding">{this.state.firstName}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><strong>Last Name</strong></th>
                                      <td className="data_padding">{this.state.lastName}</td>
                                    </tr>
                                     <tr>
                                      <th scope="row"><strong>Email</strong></th>
                                      <td className="data_padding">{this.state.email}</td>
                                    </tr>
                                     <tr>
                                      <th scope="row"><strong>Phone</strong></th>
                                      <td className="data_padding">{this.state.phonenumber}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><strong>Address</strong></th>
                                      <td className="data_padding">{(this.state.streetAddress2 !== null && this.state.streetAddress2 !== 'null' &&
                                        this.state.streetAddress2 != '') ? this.state.address1 + "," + this.state.address2 + "," +this.state.city + ", " + this.state.state : this.state.address1 + "," +this.state.city + ", " + this.state.state}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row"><strong>Zip Code</strong></th>
                                      <td className="data_padding">{this.state.zip}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                         
                        </div>
                        <div class="col-lg-6 border-left">
                  	        <h5 class="text-black mb-3 border-bottom pb-4">Your Order</h5>
                            <div class="d-flex justify-content-between">
                                <span>Selected Plan + Additional Coverage</span>
                                <div class="d-flex flex-row align-items-center">
                                    <div class="price ml-2"><span class="mr-1">price</span></div>
                                </div>
                            </div>
                            {this.state.selectedPlan != '' && 
                                <div class="d-flex justify-content-between align-items-center mt-3 p-2 items rounded border">
                                    <div class="d-flex flex-row self-center">
                                        <img class="rounded" src={fn} width="60"/>
                                        <span class="font-weight-bold d-block ml-2 align-self-center pl-2">{this.state.plan}</span>
                                    </div>
                                    <div class="d-flex flex-row align-items-center">
                                        <span class="d-block ml-5 font-weight-bold">${getPrice(this.state.plan)}</span>
                                    </div>
                                </div>
                            }
                            
                            {this.state.addons && this.state.addons.split(',').map((item, i) => {
                                                    return (
                                                        <div class="d-flex justify-content-between align-items-center mt-3 p-2 items rounded border">
                                                            <div class="d-flex flex-row self-center">
                                                                <img class="rounded" src={additional} width="60" height="20"/>
                                                                <span class="font-weight-bold d-block ml-2 align-self-center pl-2">{getAddon(item)}</span>
                                                            </div>
                                                            <div class="d-flex flex-row align-items-center">
                                                                <span class="d-block ml-5 font-weight-bold">${getAddonPrice(item)}</span>
                                                            </div>
                                                        </div>
                                                    )
                             })}
                            {this.state.tax != '' &&
                            <div class="d-flex justify-content-between information mt-5">
                                <span>Tax</span><span class="font-weight-bold">${this.state.tax}</span>
                            </div>}
                            <div class="d-flex justify-content-between information mt-1">
                                <span>Total Price</span><span class="font-weight-bold">${this.state.price}</span>
                            </div>
                            <div class="col-lg-12 mt-5 px-0">
                                    <h5 class="text-black mb-3 border-bottom pb-3">Payment Details</h5>
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <h6>Please Fill payment details Below:</h6>
                                            <Elements stripe={stripePromise}><CheckoutForm /></Elements>
                                        </div>
                            </div>

                        </div>
                        </div>
                        </div>
   					
                       </div>
                   </div>
                </section>
                <footer class="footer-section">
                    <div class="container">
                        <div className="row" style={{justifyContent:'center'}}>
                            <div class="footer-col-1">
                                <h2 class="mt-0">Stalwart Home Warranty</h2>
                                </div>
                           
				        </div>
			            <hr style={{background:'#fff', marginBottom:'30px'}}/>
                        <div className="row">
                            <div class="col-lg-8 footer-col-3">
                                <h2 >Stalwart Home Warranty</h2>
                                <p style={{ marginTop: '-25px' }}>stalwarthomewarranty@gmail.com</p>
                                <p>© 2020 - Stalwart Home Warranty,<br /> All Right Reserved</p>
                            </div>
                            <div class="col-lg-4 ml-auto mb-5 mb-lg-0 footer-col-4">
                                <div class="media">
                                    <i class="fas fa-phone-alt fa-3x mb-3"></i>
                                    <div class="media-body pl-4">
                                        <h3>CALL US NOW !</h3>
                                        <h4>(713) 314-3636</h4>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </footer>

            </div>
        );
    }
}

export default Review;