import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import { formatAddress } from '../constants/data';
import auth from '../auth/auth';


class AddNewCallModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client_id: this.props.user.id,
            property_id: null,
            description: '',
            technician: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }
    onSubmit(e) {
        e.preventDefault();
        if (this.state.property_id == '' || this.state.property_id == null) {
            alert("Please Select Property");
            return
        }
        if (this.state.description == '' || this.state.description == null) {
            alert("Please Enter Description");
            return
        }
        if (this.state.technician == '' || this.state.technician == null) {
            alert("Please Select Technician");
            return
        }
        axios({
            method: 'post',
            url: ApiUrl() + '/call/add.php',
            data: {
                property_id: this.state.property_id,
                description: this.state.description,
                technician: this.state.technician,
                client_id: this.props.user.id,
            }
        }).then(function (res) {
            if(res.token){
                auth.logout()
            }else{
                alert("Record saved successfully");
            }
        });
    }
    onChange(e) {
        if (e.target.name == 'description') {
            this.setState({ description: e.target.value });
        }
        if (e.target.name == 'property_id') {
            this.setState({ property_id: e.target.value });
        }

        if (e.target.name == 'technician') {
            this.setState({ technician: e.target.value });
        }
    }
    render() {
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title> New Call</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-color">
                <Form className="mt-2">
                    <Form.Group as={Row} controlId="userId">
                        <Form.Label column sm={4} >
                            Customer ID:
                                </Form.Label>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props.user.id}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="firstName">
                        <Form.Label column sm={4} >
                            First Name:
                                </Form.Label>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props.user.first_name}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <FormGroup as={Row} controlId="lastName">
                        <FormLabel column sm={4}>Last Name: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props.user.last_name}
                                disabled
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="address" bsSize="small" >
                        <FormLabel column sm={4}>Property Address: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="plan" size="sm" name='property_id' onChange={(e) => this.onChange(e)}>
                                <option value="0">Select Property</option>
                                {this.props.properties && this.props.properties.map((p) => (
                                    <option value={p.id}>{formatAddress(p.address1, p.address2, p.city, p.state)}</option>
                                ))}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="plan" bsSize="small" >
                        <FormLabel column sm={4}>Plan: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                value={this.props.user.plan}
                                disabled
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="description" bsSize="large" >
                        <FormLabel column sm={4}>Description: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                name='description'
                                size="sm"
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="technician" bsSize="small" >
                        <FormLabel column sm={4}>Assign to Technician: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" controlId="technician" name='technician' size="sm" onChange={(e) => this.onChange(e)}>
                                <option value="0">Select Technician</option>
                                {this.props.technician && this.props.technician.map((t) => (
                                    <option value={t.id}>{t.name}</option>
                                ))}
                            </FormControl>
                        </Col>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={(e) => this.onSubmit(e)}>Save </Button>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(AddNewCallModel);