import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import Thanks from './thanks';
import termsAndCondition from '../../src/documents/StalwartContract.pdf';

import axios from "axios";
import { PaymentApiUrl } from '../constants/api';
import { ApiUrl } from '../constants/api';
import { paymentSecretKeyProd, homePageUrl, thanksPageUrl } from '../constants/data';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

const stripePromise = loadStripe(paymentSecretKeyProd());
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
            border: "1px solid #ddd",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};
class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onTermAndConditionChanged: false,
            checkInformationChecked: false,
            bankName: '',
            checkNumber: '',
            loading: false,
            isThankOpen: false,
            shwId: '',
            data: {
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                total: localStorage.getItem("total"),
            }
        }
    }
    CallAddProperty(paymentID, postData, self) {
        axios({
            method: 'post',
            url: ApiUrl() + '/client/add.php',
            data: {
                first_name: postData.firstName,
                last_name: postData.lastName,
                email: postData.email,
                zipcode: postData.zip,
                address1: postData.address1,
                address2: postData.address2,
                city: postData.city,
                phonenumber: postData.phonenumber,
                state: postData.state,
                plan_id: postData.plan_type,
                price: postData.price,
                scf_price: postData.scf_price,
                total_price: postData.price,
                poia_zipcode: postData.propertyZip,
                poia_address: postData.streetAddress,
                poia_address2: postData.streetAddress2,
                poia_city: postData.propertycity,
                poia_state: postData.propertystate,
                addons: postData.addons,
                stripeToken: postData.stripeToken,
                payment_type: this.state.checkInformationChecked ? 1 : 0,
                payment_id: this.state.checkInformationChecked ? "" : paymentID,
                bank_name: this.state.checkInformationChecked ? this.state.bankName : "",
                check_number: this.state.checkInformationChecked ? this.state.checkNumber : "",
            }
        }).then(function (res) {
            if (res.status) {
                localStorage.setItem("shwId", res.data.shwid);
                self.setState({ loading: !self.state.loading, shwId: res.data.shwid });
                window.location.replace(thanksPageUrl());
            }
            else {
                self.setState({
                    loading: !self.state.loading
                });
                alert(res.message);
                window.localStorage.clear();
            }
        }).catch((error) => {
            if (error) {
                alert(error?.response); // => the response payload 
            }
            self.setState({ loading: !self.state.loading });

        });
    }

    handleSubmit = async (event) => {
        
        // this.context.router.push('/thanks');
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        var self = this;
        var data = {
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            zip: localStorage.getItem("zip"),
            email: localStorage.getItem("email"),
            address1: localStorage.getItem("address1"),
            address2: localStorage.getItem("address2"),
            propertyZip: localStorage.getItem("propertyZip"),
            streetAddress: localStorage.getItem("streetAddress"),
            SameCurrentAddressChanged: localStorage.getItem("SameCurrentAddressChanged"),
            plan: localStorage.getItem("plan"),
            phonenumber: localStorage.getItem("phonenumber"),
            city: localStorage.getItem("city"),
            state: localStorage.getItem("state"),
            propertycity: localStorage.getItem("propertycity"),
            propertystate: localStorage.getItem("propertystate"),
            streetAddress2: localStorage.getItem("streetAddress2"),
            selectedPlan: localStorage.getItem("selectedPlan"),
            scf_price: localStorage.getItem("scf_price"),
            price: localStorage.getItem("total"),
            plan_type: localStorage.getItem("plan_type"),
            addons: localStorage.getItem("addons")
        }
        if (this.state.checkInformationChecked) {
            if (this.state.bankName == '' || this.state.bankName == null) {
                alert("Please Enter bank Name");
                return
            }
            if (this.state.checkNumber == '' || this.state.checkNumber == null) {
                alert("Please Enter CHECK number");
                return
            }
            self.CallAddProperty('', data, self);
        }
        else {
            const { stripe, elements } = this.props
            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make  sure to disable form submission until Stripe.js has loaded.
                return;
            }
            self.setState({ loading: !self.state.loading });
            console.log(elements.getElement(CardElement))
            stripe.createToken(elements.getElement(CardElement)).then(function (result) {
                if (result.error) {
                    alert(result.error.message);
                    self.setState({ loading: !self.state.loading });
                    // Inform the customer that there was an error.
                    // var errorElement = document.getElementById('card-errors');
                    // errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server.
                    // stripeTokenHandler(result.token);
                    
                    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                    axios({
                        method: 'post',
                        url: PaymentApiUrl() + '/charge.php',
                        data: {
                            first_name: data.firstName,
                            last_name: data.lastName,
                            email: data.email,
                            zipcode: data.zip,
                            address1: data.address1,
                            address2: data.address2,
                            city: data.city,
                            phonenumber: data.phonenumber,
                            state: data.state,
                            plan_id: data.plan_type,
                            price: data.price,
                            scf_price: data.scf_price,
                            total_price: data.price,
                            poia_zipcode: data.propertyZip,
                            poia_address: data.streetAddress,
                            poia_address2: data.streetAddress2,
                            poia_city: data.propertycity,
                            poia_state: data.propertystate,
                            addons: data.addons,
                            stripeToken: result.token.id
                        }
                    }).then(function (res) {
                        self.CallAddProperty(res.data.payment_id, data, self);

                    }).catch((error) => {
                        if (error) {
                            alert(error?.response); // => the response payload 
                        }
                        self.setState({ loading: !self.state.loading });

                    });
                    console.log(result.token)
                }
            })
        }
    };

    closeCallNewModel = () => {
        this.setState({ isThankOpen: false });
        window.localStorage.clear();
        window.location.replace(homePageUrl());
    }

    onTermAndConditionChanged = e => {
        console.log(e.currentTarget.checked,"--e.currentTarget.checked");
        this.setState({ termAndCondition: e.currentTarget.checked });
    }
    checkInformationChanged = e => {
        this.setState({ checkInformationChecked: e.currentTarget.checked });
    }
    onBankNameChanged = e => {
        this.setState({ bankName: e.currentTarget.value });
    }
    onCheckNumberChanged = e => {
        this.setState({ checkNumber: e.currentTarget.value });
    }
    render() {
        const { stripe } = this.props;

        return (
            <div className="col-sm-12 margin-right margin-left">
                {this.state.loading &&
                    <Loader fullPage loading={this.state.loading} />}
                <form id="payment" onSubmit={this.handleSubmit}>
                    {((sessionStorage.getItem("ur") == 2 || sessionStorage.getItem("ur") == 1)) &&
                        <div className="col-sm-12 margin-right margin-left mt-3">
                            <label>
                                <input type="checkbox" onChange={this.checkInformationChanged} /> Pay by CHECK, Enter CHECK information
                        </label>
                        </div>
                    }
                    {!this.state.checkInformationChecked &&
                        <CardSection />
                    }
                    { this.state.checkInformationChecked &&
                        <div>
                        <div class="form-group row">
                            <label for="" class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control input-text" id="" placeholder="Bank Name" name="bankName" value={this.state.bankName} onChange={this.onBankNameChanged} />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="col-sm-3 col-form-label">Check Number</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control input-text" id="" placeholder="Check Number" name="checkNumber" value={this.state.checkNumber} onChange={this.onCheckNumberChanged} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control input-text" id="" placeholder="Amount" name="amount" value={localStorage.getItem("total")} readOnly />
                            </div>
                        </div>
                        </div>
                        }
                    <div className="col-sm-12 margin-right margin-left">
                        <label>
                            <input type="checkbox" onChange={this.onTermAndConditionChanged} /> By clicking this you are accepting <a className="no-padding" href={termsAndCondition} target="_blank"> Terms & Condition </a>
                        </label>
                    </div>
                    <button className="btn make-payment-btn" type="submit" disabled={!this.state.termAndCondition}>Make Payments</button>
                    <div className="row" style={{ minHeight: 20 + "px" }}></div>
                </form>
            </div>
        );
    }
}
// export default CheckoutForm
export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <div><CheckoutForm stripe={stripe} elements={elements} /></div>
            )}
        </ElementsConsumer>
    );

}
