import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import { getUsStates } from '../constants/data';
import auth from '../auth/auth'



class AddEditModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            id: '',
            poia_id:'',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            status: 1
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            id: nextProps?.user?.id,
            email: nextProps?.user?.email,
            first_name: nextProps?.user?.first_name,
            last_name: nextProps?.user?.last_name,
            phone_number: nextProps?.user?.phone_number,
            address1: nextProps?.user?.address1,
            address2: nextProps?.user?.address2,
            city: nextProps?.user?.city,
            state: nextProps?.user?.state,
            zipcode: nextProps?.user?.zipcode,
            status: nextProps?.user?.status
        })
    }
    onSubmit(e) {
        e.preventDefault();
        if (this.state.first_name == '' || this.state.first_name == null) {
            alert("Please Enter First Name");
            return
        }
        if (this.state.last_name == '' || this.state.last_name == null) {
            alert("Please Enter Last Name");
            return
        }
        if (this.state.address1 == '' || this.state.address1 == null) {
            alert("Please Enter Address1");
            return
        }
        if (this.state.email == '' || this.state.email == null) {
            alert("Please enter email");
            return;
        }
        else {
            var inValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email);
            if (!inValidEmail) {
                alert("Please enter valid email");
            }
        }
        if (this.state.zipcode == '' || this.state.zipcode == null) {
            alert("Please Enter Zip");
            return
        }
        if (this.state.city == '' || this.state.city == null) {
            alert("Please Enter City Name");
            return
        }
        if (this.state.state == '' || this.state.state == null) {
            alert("Please Enter State");
            return
        }
        axios({
            method: 'post',
            url:  ApiUrl() + '/client/edit.php',
            data: {
                id: this.state.id,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                phone_number: this.state.phone_number,
                zipcode: this.state.zipcode,
                status: this.state.status
            }
        }).then(function (res) {
            if(res.token){
                auth.logout()
            }else{
                alert("Record saved successfully");
            }
        }).catch((error) => {
            if (error) {
                alert("Something went wrong, Please try again."); 
            }
        });;
    }
    onChange(e) {
        if (e.target.name === "first_name") {
            this.setState({ "first_name": e.target.value });
        }
        if (e.target.name === "last_name") {
            this.setState({ "last_name": e.target.value });
        }
        if (e.target.name === "phone_number") {
            this.setState({ "phone_number": e.target.value });
        }
        if (e.target.name === "address1") {
            this.setState({ "address1": e.target.value });
        }
        if (e.target.name === "address2") {
            this.setState({ "address2": e.target.value });
        }
        if (e.target.name === "city") {
            this.setState({ "city": e.target.value });
        }
        if (e.target.name === "state") {
            this.setState({ "state": e.target.value });
        }
        if (e.target.name === "zipcode") {
            this.setState({ "zipcode": e.target.value });
        }
        if (e.target.name === "plan") {
            this.setState({ "plan_id": e.target.value });
        }
        if (e.target.name === "status") {
            this.setState({ "status": e.target.value });
        }
    }

    render() {
        var formType = "Add";
        if (this.props.edit == true) {
            formType = "Edit"
        }
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title>{formType} Client</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-right font-color">
                <Form className="mt-2">
                    <FormGroup as={Row} controlId="status" bsSize="small" >
                        <FormLabel column sm={4}>Status: </FormLabel>
                        <Col sm={8}>
                            <FormControl as="select" name="status" controlId="status" size="sm" placeholder="Set Status" onChange={(e) => this.onChange(e)}>
                                <option value="0" selected={this.state.status == 0 ? true : false} >InActive</option>
                                <option value="1" selected={this.state.status == 1 ? true : false} >Active</option>
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <Form.Group as={Row} controlId="firstName">
                        <Form.Label column sm={4} >
                            First Name:
                                </Form.Label>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='first_name'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.first_name}
                            />
                        </Col>
                    </Form.Group>
                    <FormGroup as={Row} controlId="lastName">
                        <FormLabel column sm={4}>Last Name: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='last_name'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.last_name}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="phoneNumber" bsSize="small" >
                        <FormLabel column sm={4}>Phone number: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='phone_number'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.phone_number}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="email" bsSize="small" >
                        <FormLabel column sm={4}>Email: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='email'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.email}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup as={Row} controlId="address1" bsSize="large" >
                        <FormLabel column sm={4}>Address1: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                name='address1'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.address1}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="address2" bsSize="small" >
                        <FormLabel column sm={4}>Address2: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='address2'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.address2}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="city" bsSize="small" >
                        <FormLabel column sm={4}>City: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='city'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.city}
                            />
                        </Col>

                    </FormGroup>
                    <FormGroup as={Row} controlId="state" bsSize="small" >
                        <FormLabel column sm={4}>State: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                as="select"
                                controlId="state"
                                name='state'
                                size="sm"
                                onChange={(e) => this.onChange(e)}>
                                <option value="-1">Select State</option>
                                {getUsStates().map((v) =>
                                    (
                                        <option value={v} selected={v == this.state.state}>{v}</option>
                                    )
                                )}
                            </FormControl>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="zipcode" bsSize="small" >
                        <FormLabel column sm={4}>Zip code: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                size="sm"
                                autoFocus
                                name='zipcode'
                                onChange={(e) => this.onChange(e)}
                                value={this.state.zipcode}
                            />
                        </Col>
                    </FormGroup>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={(e) => this.onSubmit(e)}>Save </Button>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(AddEditModel);