import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { ApiUrl } from '../constants/api';
import { getUserRole } from '../constants/data';
import EditUserModel from './EditUserModel';
import AddNewUserModel from './AddNewUserModel';
import { Loader } from 'react-overlay-loader';
import '../styles/dataTable.css';
import dashboard from './dashboard';
import ClientsAndCalls from './ClientsAndCalls';
import Reports from './reports';
import fetchHeaders from '../helpers/fetch';


class SuperAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            user: {},
            isEditUserOpen: false,
            isNewUserOpen: false,
            loading: true,
            filter_status: "1",
            selected_panel: 0,
        }
    }

    fetch_user_list() {
        var that = this;
        fetch(ApiUrl() + '/user/list.php?status=' + this.state.filter_status, {
            headers: fetchHeaders()
        })
            .then(response => response.json())
            .then(function (res) {
                if (!res.status) {
                    that.setState({ users: null, errorMessage: res.message, loading: false });
                }
                else {
                    that.setState({ users: res.users, loading: false, errorMessage: '' });
                }
            });
    }
    closeEditUserModal = () => {
        this.setState({ isEditUserOpen: false });
        this.fetch_user_list();
    }
    closeNewUserModal = () => {
        this.setState({ isNewUserOpen: false });
        this.fetch_user_list();
    }
    openEditUserModal(e, u) {
        e.preventDefault();
        this.setState({
            isEditUserOpen: true,
            user: u
        });
    }
    openNewUserModal() {
        this.setState({
            isNewUserOpen: true
        });
    }
    onFilterStatusChange(e) {
        this.setState({ filter_status: e.target.value });
    }
    deleteUser(e, u) {
        var that = this;
        if (window.confirm('Are you sure you want to InActive this record?')) {
            e.preventDefault();
            axios({
                method: 'post',
                url: ApiUrl() + '/user/delete.php',
                data: {
                    id: u.id
                }
            }).then(function (res) {
                if (res.data.status) {
                    alert("Record updated successfully");
                    that.fetch_user_list();
                }
                else
                    alert(res.data.message);
            });
        }
    }
    componentDidMount() {
        this.fetch_user_list();
    }
    onPanelButtonClick(value) {
        this.setState({ selected_panel: value });
    }
    onGoClick(e) {
        this.fetch_user_list();
    }
    render() {
        const users = this.state.users && this.state.users.map((user, index) => {
            return {
                id: index + 1,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                role: getUserRole(user.role),
                status: user.status == 1 ? "Active" : "InActive",
                edit_user: <button className="btn btn-warning" onClick={(e) => this.openEditUserModal(e, user)} ><FontAwesomeIcon icon={faPencilAlt} /></button>,
                delete_user: <button className="btn btn-warning" onClick={(e) => this.deleteUser(e, user)}><FontAwesomeIcon icon={faTrashAlt} /></button>
            }
        });
        let data = {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 200,
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Id',
                    },
                },
                {
                    label: 'First Name',
                    field: 'firstName',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Last Name',
                    field: 'lastName',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Phone',
                    field: 'phone',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Role',
                    field: 'role',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Edit User',
                    field: 'edit_user',
                    width: 200,
                },
                {
                    label: 'Delete User',
                    field: 'delete_user',
                    width: 200,
                }
            ],
            rows: users || [],
        };

        return (
            <section class="login-header">
            <div className="font-color container">
                <div className="row">
                    <div className="col-sm-6 mt-2">
                        <button className="btn user4-panel-button ml-3" onClick={(e) => this.onPanelButtonClick(0)} style={this.state.selected_panel == 0 ? { backgroundColor: "#EE8129" } : { backgroundColor: "white" }}>Reports</button>
                        <button className="btn user4-panel-button ml-1" onClick={(e) => this.onPanelButtonClick(1)} style={this.state.selected_panel == 1 ? { backgroundColor: "#EE8129" } : { backgroundColor: "white" }}>Clients & Calls</button>
                        <button className="btn user4-panel-button margin-left-5" onClick={(e) => this.onPanelButtonClick(2)} style={this.state.selected_panel == 2 ? { backgroundColor: "#EE8129" } : { backgroundColor: "white" }}>Users</button>
                    </div>
                    <div className="col-sm-6 mt-1">
                        <div className="text-right mt-2">Welcome {sessionStorage.email} </div>
                        <div className="text-right mt-2">
                            <Link to='/logout'>Logout</Link>
                        </div>
                    </div>
                </div>
                {this.state.selected_panel == 2 &&
                    <div>
                        <div className="row">
                            <div className="col-sm-2 mt-5 text-left stal"><strong>Users </strong></div>
                            <div className="col-sm-10 text-right active-cyan-4 mt-5">
                                <a className="btn btn-primary float-right font-color-white" onClick={() => this.openNewUserModal()}> +New User </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mt-1 text-right stal">
                                <span className="font-size-15">Filter By Status:</span>
                                <select id="filter_status" className="font-size-15 ml-1" name="filter_status" onChange={(e) => this.onFilterStatusChange(e)}>
                                    <option value="-1" selected={this.state.filter_status == "-1" ? true : false}>All</option>
                                    <option value="0" selected={this.state.filter_status == "0" ? true : false}>InActive</option>
                                    <option value="1" selected={this.state.filter_status == "1" ? true : false}>Active</option>
                                </select>
                                <button className="font-size-15 ml-1" onClick={(e) => this.onGoClick(e)} name="go_click">Go</button>
                            </div>
                            <div className="col-sm-12 text-center active-cyan-4 mb-4 mt-1">

                                <MDBDataTable
                                    bordered
                                    responsive
                                    hover
                                    small
                                    data={data}
                                    maxHeight="200px"
                                />
                            </div>
                        </div>
                        <EditUserModel show={this.state.isEditUserOpen} close={this.closeEditUserModal} user={this.state.user} />
                        <AddNewUserModel show={this.state.isNewUserOpen} close={this.closeNewUserModal} />
                    </div>
                }
                {this.state.selected_panel == 1 &&
                    <ClientsAndCalls></ClientsAndCalls>
                }
                {this.state.selected_panel == 0 &&
                    <Reports></Reports>
                }
            </div>
            </section>
        );
    }
}
export default withRouter(SuperAdmin);