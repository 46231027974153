import React, { Component } from 'react';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import '../styles/home.css';
import { BrowserRouter as Router, Route, withRouter, Link, Switch, Redirect } from 'react-router-dom';
import { ApiUrl } from '../constants/api';
import auth from '../auth/auth'
import axios from 'axios';



class Login extends Component {
    constructor(props) {
        super(props);
          this.state = {
            email: '',
            password: '',
            redirect: false,
            error: false,
            errormessage: ''
        };
    }
    
    setEmail = e => {
        this.setState({ error: false, errormessage: "" });
        this.setState({ email: e });
    }
    setPassword = e => {
        this.setState({ error: false, errormessage: "" });
        this.setState({ password: e });
    }

    validateForm = () => {
        return this.state.email.length > 0 && this.state.password.length > 0 &&
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email);
    }
    handleSubmit(e) {
        e.preventDefault();
        var that = this;
        axios({
            method: 'post',
            url: ApiUrl() + '/user/login.php',
            data: {
                email: this.state.email,
                password: this.state.password,
            }
        }).then(function (res) {
            res = res?.data;
            if (res.status == true) {
                if(res.token){
                    sessionStorage.setItem('token', res.token);
                    sessionStorage.setItem('ur', res.data.Role);
                    sessionStorage.setItem('uid', res.data.id);
                    sessionStorage.setItem('email', res.data.email);
                    console.log(sessionStorage.token);          
                    auth.login();
                }
                
                var reditectPath = '';
                var role = res.data.Role;
                if (role == 3) {
                    reditectPath = '/techniciandashboard';
                }
                else if (role == 2) {
                    reditectPath = '/dashboard';
                }
                else if (role == 1) {
                    reditectPath = '/superadmin';
                }
                that.props.history.push({
                    pathname: reditectPath
                });
            }
            else {
                that.setState({ error: true, errormessage: res?.message});
            }
        });

    }

    render() {

        return (
            <div className="container-fluid">
                <section class="login-header">
            <div className="Login font-color">
                <h2 className="text-align-center">Stalwart Home Warranty</h2>
                <h2 className="text-align-center">Admin Portal</h2>
                <form>
                    {this.state.error &&
                        <div class="alert alert-danger" role="alert">
                        {this.state.errormessage}
                        </div>}
                    <FormGroup controlId="email" bsSize="large" className="text-left">
                        <FormLabel>Email</FormLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={this.state.email}
                            onChange={e => this.setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large" className="text-left">
                        <FormLabel>Password</FormLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={e => this.setPassword(e.target.value)}
                            type="password"
                        />
                    </FormGroup>

                    <Button onClick={this.handleSubmit.bind(this)} block bsSize="large" disabled={!this.validateForm()} type="submit">
                        Login
                </Button>
                </form>
            </div>
            </section>
            </div>
        );
    }
}
export default withRouter(Login);