let fetchHeaders = () => {
    let bearer = `Bearer ${sessionStorage.token}`;

    return {
        'Authorization': bearer,
        'Content-Type': 'application/json'
    }
};


export default fetchHeaders;