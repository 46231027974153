import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { ApiUrl } from '../constants/api';


class EditCallTechnicianModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            call_id: '',
            call_status: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            comment: nextProps?.call?.comment,
            call_id: nextProps?.call?.id,
            call_status: nextProps?.call?.status,
        })
    }
    onSubmit(e, status) {
        e.preventDefault();
        if (this.state.comment == '' || this.state.comment == null) {
            alert("Please enter any comment");
            return
        }
        this.setState({
            call_status: status
        });
        axios({
            method: 'post',
            url: ApiUrl()+ '/call/edit.php',
            data: {
                comment: this.state.comment,
                id: this.state.call_id,
                status: status
            }
        }).then(function (res) {
            alert("Record updated successfully");
        });
    }
    onChange(e) {
        debugger;
        if (e.target.name == 'comment') {
            this.setState({ comment: e.target.value });
        }  
    }
    render() {
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header className="text-center choose" closeButton>
                <Modal.Title> Update Call </Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-color">
                <Form className="mt-2">
                    <Form.Group as={Row} controlId="callId">
                        <Form.Label column sm={4} >
                            Work Order ID: <strong> {this.props?.call?.shwId} </strong>
                        </Form.Label>
                        <Form.Label column sm={6} >
                            Service Address: <strong> {this.props?.call?.address} </strong>
                        </Form.Label>
                    </Form.Group>
                    <FormGroup as={Row} controlId="comment" bsSize="large" >
                        <FormLabel column sm={4}>Comments: </FormLabel>
                        <Col sm={8}>
                            <FormControl
                                as="textarea"
                                rows="3" 
                                name='comment'
                                size="sm"
                                value={this.state.comment}
                                onChange={(e) => this.onChange(e)}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-primary" type="submit" onClick={(e) => this.onSubmit(e, 0)}> Comment and Mark Resovled </Button>
                <Button className="btn-warning" type="submit" onClick={(e) => this.onSubmit(e, 1)}> Comment and Leave Open </Button>
                <Button variant="secondary" onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
export default withRouter(EditCallTechnicianModel);