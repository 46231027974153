import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AddCallModel from './AddCallModel';
import AddEditModel from './AddEditModel';
import { ApiUrl } from '../constants/api';
import { formatAddress, plansAndPriceUrl } from '../constants/data';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import '../styles/dataTable.css';
import auth from '../auth/auth'
import fetchHeaders from '../helpers/fetch';

class ClientsAndCalls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            details: [],
            openCalls: [],
            user: {},
            editUser: {},
            errorMessage: '',
            email: sessionStorage.email,
            filter_status: "1",
        }
    }
    state = {
        isOpen: false,
        isCollOpen: false,
        isCallnewOpen: false,
    };
    fetch_order_list() {
        var that = this;
        fetch(ApiUrl() + '/client/list.php?status=' + this.state.filter_status, {
            headers:fetchHeaders()
        })
            .then(response => response.json())
            .then(function (res) {
                if(res.token){
                    auth.logout()
                }else{
                    if (!res.status) {
                        that.setState({ details: null, errorMessage: res.message, loading: false });
                    }
                    else {
                        that.setState({ details: res.data, loading: false, errorMessage: '' });
                    }
                }
            });
    }
    componentDidMount() {
        this.fetch_order_list();
    }
    closeModal = () => {
        this.setState({ isOpen: false });
        this.fetch_order_list();
    }
    openModal(u) {
        this.setState({
            isOpen: true,
            isEdit: true,
            editUser: u
        });
    }
    openCallModal(u) {
        fetch(ApiUrl() + '/call/list.php?id=' + u.id, {
            headers:fetchHeaders()
        })
            .then(response => response.json())
            .then(data => {
                if(data.token){
                    auth.logout()
                }else{
                    data.message && data.message.toLowerCase() == "no data found." ? this.setState({ openCalls: null }) : this.setState({ openCalls: data })
                }
            });

        this.setState({
            isCollOpen: true,
            user: u
        });
    }

    closeCallModal = () => {
        this.setState({ isCollOpen: false });
        this.fetch_order_list();
    }
    onGoClick(e) {
        this.fetch_order_list();
    }
    onFilterStatusChange(e) {
        this.setState({ filter_status: e.target.value });
    }
    render() {
        const details = this.state.details && this.state.details.map((user, index) => {
            return {
                id: index + 1,
                first_name: user.first_name,
                last_name: user.last_name,
                plan: user.plan,
                phone_number: user.phone_number,
                covered_property: formatAddress(user.poia_address1, user.poia_address2, user.poia_city, user.state),
                calls: <div onClick={() => this.openCallModal(user)}><a href='#' className='underline'> {user.call_count} Open</a></div>,
                status: user.status == 1 ? "Active" : "InActive",
                poia_status: user.poia_status == 1 ? "Active" : "InActive",
                edit_client: <button className="btn btn-warning" onClick={() => this.openModal(user)}><FontAwesomeIcon icon={faPencilAlt} /></button>
            }
        });

        let data = {
            columns: [
                {
                    label: 'First Name',
                    field: 'first_name',
                    sort: 'asc',
                    width: 200,
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                {
                    label: 'Last Name',
                    field: 'last_name',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Plan',
                    field: 'plan',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Phone Number',
                    field: 'phone_number',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Covered Property',
                    field: 'covered_property',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Calls',
                    field: 'calls',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Client Status',
                    field: 'status',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Property Status',
                    field: 'poia_status',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: 'Edit Client',
                    field: 'edit_client',
                    width: 200,
                }
            ],
            rows: details || [],
        }

        return (
            <div className="font-color container">
                <div className="row">
                    <div className="col-sm-2 mt-5 text-left stal "><strong>Clients </strong></div>
                    <div className="col-sm-10 text-right active-cyan-4 mt-5">
                        <a className="btn btn-primary float-right" target = "_self" href={plansAndPriceUrl()} > +New Client </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center active-cyan-4 mb-4 mt-3">
                        <div className="col-sm-12 mt-1 text-right stal">
                            <span className="font-size-15">Filter By Status:</span>
                            <select id="filter_status" className="font-size-15 ml-1" name="filter_status" onChange={(e) => this.onFilterStatusChange(e)}>
                                <option value="-1" selected={this.state.filter_status == "-1" ? true : false}>All</option>
                                <option value="0" selected={this.state.filter_status == "0" ? true : false}>InActive</option>
                                <option value="1" selected={this.state.filter_status == "1" ? true : false}>Active</option>
                            </select>
                            <button className="font-size-15 ml-1" onClick={(e) => this.onGoClick(e)} name="go_click">Go</button>
                        </div>
                        <MDBDataTable
                            bordered
                            responsive
                            hover
                            small
                            data={data}
                            maxHeight="200px"
                        />
                    </div>
                </div>
                <AddEditModel show={this.state.isOpen} close={this.closeModal} user={this.state.editUser} edit={this.state.isEdit} />
                <AddCallModel
                    show={this.state.isCollOpen}
                    openCalls={this.state.openCalls}
                    user={this.state.user}
                    close={this.closeCallModal}
                    handlenewcollopenpopup={this.handlenewcollopenpopup}
                />
            </div>
        );
    }
}
export default withRouter(ClientsAndCalls);