import axios from 'axios';

let token ;

if (sessionStorage.token) {
    token = `Bearer ${sessionStorage.token}`;
}
axios.interceptors.request.use( request => {
    request.headers.Authorization = token;
    console.log(request)
    return request;
});

