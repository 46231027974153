
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import Logo from '../../images/StalwartLogo.jpeg';
import about from '../../images/about1.png';
// import '../../styles/home.css';


class Homecontent extends React.Component {
    render() {
        return (
            <div>
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center justify-content-center text-left">
                            <div className="col-lg-12">
                                <h1 className="font-weight-bold">Join <span>1.8 Million Customers</span><br/> Who Already Have Coverage </h1>
                            </div>
                        </div>
                        <div className="banner-phone banner-phone-web"><a href="tel:7133143636"><i className="fas fa-phone-alt"></i>( 713 ) 314-3636</a></div>
                    </div>
                </header>
                <section className="page-section about-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-left about-section-left">
                                <h2 className="mt-0">About Us</h2>
                                <p className="mb-4">Stalwart Home Warranty LLC (SHW) is based in Pearland, Texas. The company was formed to provide home warranty coverage for Houston, its adjacent suburbs, and cities. Our mission is to provide good, quality, and most importantly peace of mind to our customers.</p>
                            <p> Being a locally-based company, SHW is able to provide quality service in a timely manner to our members. Therefore, our client's comfort is our business</p>
                            </div>
                            <div className="col-lg-6 about-section-right">
                            <img className="img-fluid" src={about}/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="page-section services-section">
                    <div className="container">
                        <h2 className="text-left h-services mt-0"> Stalwart Home Warranty <br/> Plan and Prices </h2>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="mt-5 services-list services-list1">
                                    <h3 className="h4 mb-2">STANDARD COVERAGE</h3>
                                    <ul>
                                    <li>    Primary Gas, Oil or Electric Heater</li>
                                    <li>Air Conditioner (Two System per coverage)</li>
                                    <li>  Ductwork</li>
                                    <li>Garbage Disposal</li>
                                    <li>Electrical</li>
                                    <li>Built-in Microwave</li>
                                    </ul>
                                    <Link className="services-list-button" to={{
                                        pathname: '/plansandpricing',
                                        plan: '0'
                                    }}>GET STARTED</Link>                               
                                </div>	
                            </div>
                        <div className="col-lg-4 col-md-6">
                                <div className="mt-5 services-list services-list2">
                                    <h3 className="h4 mb-2">GOLD COVERAGE</h3>
                                    <ul>
                                    <li>All of Standard Coverage, Plus</li>
                                    <li> Refrigerant, Recapture, Reclaim & Disposal</li>
                                    <li>Permits, Haul Away & Disposal</li>
                                    <li> Mismacthed System(HVAC)</li>
                                    </ul>
                                    <Link className="services-list-button" to={{
                                        pathname: '/plansandpricing',
                                        plan: '1'
                                    }}>GET STARTED</Link>
                                </div>	
                            </div>
                                <div className="col-lg-4 col-md-6">
                                <div className="mt-5 services-list services-list3">
                                    <h3 className="h4 mb-2">PLATINUM COVERAGE</h3>
                                    <ul>
                                    <li>All of Standard & Gold Coverage, Plus</li>
                                    <li> Increased Coverage for Plumbing items/Stoppages</li>
                                    <li>Increased Coverage for Improper installation(HVAC)</li>
                                    <li> Mismacthed System(HVAC)</li>
                                    </ul>
                                    <Link className="services-list-button" to={{
                                        pathname: '/plansandpricing',
                                        plan: '2'
                                    }}>GET STARTED</Link>
                                </div>	
                            </div>
                            
                            <div className="col-lg-12 mt-5">
                                <Link to='/plansandpricing' className="get-quote-btn" style={{ color: 'white', paddingLeft: 1 + '%', paddingRight: 1 + '%' }}>GET STARTED</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="footer-section">
                    <div className="container">
                        <div className="row" style={{justifyContent:'center'}}>
                            <div className="footer-col-1">
                                <h2 className="mt-0">Stalwart Home Warranty</h2>
                                </div>
                           
				        </div>
			            <hr style={{background:'#fff', marginBottom:'30px'}}/>
                        <div className="row">
                            <div className="col-lg-8 footer-col-3">
                                <h2 >Stalwart Home Warranty</h2>
                                <p style={{ marginTop: '-25px' }}>stalwarthomewarranty@gmail.com</p>
                                <p>© 2020 - Stalwart Home Warranty,<br /> All Right Reserved</p>
                            </div>
                            <div className="col-lg-4 ml-auto mb-5 mb-lg-0 footer-col-4">
                                <div className="media">
                                    <i className="fas fa-phone-alt fa-3x mb-3"></i>
                                    <div className="media-body pl-4">
                                        <h3>CALL US NOW !</h3>
                                        <h4>(713) 314-3636</h4>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </footer>
                
            </div>
        );
    }
}
export default Homecontent;
