import React, { Component } from 'react'
// import '../styles/home.css'
import '../styles.css';

import axios from 'axios'
import Home from './home'
import Details from './details'
import Logo from '../images/StalwartLogo.jpeg'
import RightImg from '../images/right-image.jpeg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/Alert'
import { BrowserRouter as Router, Route, withRouter, Link, Switch, Redirect } from 'react-router-dom'
import { getPrice, getSCF, calculateTax } from '../constants/data';


class AddButton extends Component {
    render() {

        return <p class="mt-1">
            <span>{this.props.heading}</span>
            <span>${this.props.price}</span>

            {(this.props.flag) ?
                <button style={{marginLeft: 4 + "px"}} name={this.props.heading} value={this.props.coverage} onClick={this.props.onAddCoverage}><b>+</b></button> :
                <button name={this.props.heading} value={this.props.coverage} onClick={this.props.onRemoveCoverage}><b>-</b></button>
            }
        </p>
    }
}

class AddColumn extends Component {
    render() {
        return <tr>
            <td>{this.props.coveredPlan}</td>
            <td class="text-center"><i class="fas fa-check-circle text-success" style={{ fontSize: '30px' }}></i> </td>
            {this.props.isMobile &&
                <> <td class="text-center"><i class="fas fa-check-circle text-success" style={{ fontSize: '30px' }}></i> </td>
                    <td class="text-center"><i class="fas fa-check-circle text-success" style={{ fontSize: '30px' }}></i> </td>
                </>
            }
        </tr>
    }
}

class AddColumngold extends Component {
    render() {
        return <tr>
            <td>{this.props.coveredPlan}</td>
            <td class="text-center"><i class="fas fa-times-circle text-danger" style={{ fontSize: '30px' }}></i></td>
            {this.props.isMobile &&
                <> <td class="text-center"><i class="fas fa-check-circle text-success" style={{ fontSize: '30px' }}></i></td>
                    <td class="text-center"><i class="fas fa-check-circle text-success" style={{ fontSize: '30px' }}></i></td>
                </>
            }
        </tr>
    }
}

class AddColumnplatinum extends Component {
    render() {
        return <tr>
            <td>{this.props.coveredPlan}</td>
            <td class="text-center"><i class="fas fa-times-circle text-danger" style={{ fontSize: '30px' }}></i></td>
            {this.props.isMobile &&
                <>
                    <td class="text-center"><i class="fas fa-times-circle text-danger" style={{ fontSize: '30px' }}></i></td>
                    <td class="text-center"><i class="fas fa-check-circle text-success" style={{ fontSize: '30px' }}></i></td>
                </>
            }
        </tr>
    }
}

class Planandpricing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            selectedPlan: '',
            addons: [],
            additional_coverage: [],
            totalPrice: 0,
            flag: {
                one: true,
                two: true,
                three: true,
                four: true,
                five: true,
                six: true,
                seven: true,
                eight: true,
                nine: true,
                ten: true,
                eleven: true
            },
            error: false,
            price: 0.0,
            scf_price: 0.0,
            tax: 0.0,
            //standardChevronDown: false,
            //goldChevronDown: false,
            //platniumChevronDown: false,
        };
        this.onAddCoverage = this.onAddCoverage.bind(this);
        this.onRemoveCoverage = this.onRemoveCoverage.bind(this);
        //this.selectedplan = {selectedplan:''};
    }

    componentDidMount() {

        if (this.props.location.plan == 0) {
            this.setState({
                selectedPlan: 'standard',
                price: 375,
                scf_price: 75
            })
            localStorage.setItem("plan_type", 1);
        }
        if (this.props.location.plan == 1) {
            this.setState({

                selectedPlan: 'gold',
                price: 475,
                scf_price: 70
            })
            localStorage.setItem("plan_type", 2);

        }
        if (this.props.location.plan == 2) {
            this.setState({
                selectedPlan: 'platinum',
                price: 650,
                scf_price: 65
            })
            localStorage.setItem("plan_type", 3);
        }

    }

    onPlanChanged = (name) => {
        if (name == 'standard') {

            this.setState({
                selectedPlan: name,
                price: 375,
                scf_price: 75
            });
            localStorage.setItem("selectedPlan", name);
            localStorage.setItem("price", 375);
            localStorage.setItem("scf_price", 75);
            localStorage.setItem("plan_type", 1);

        }
        if (name == 'gold') {

            this.setState({
                selectedPlan: name,
                price: 475,
                scf_price: 70
            });

            localStorage.setItem("selectedPlan", name);
            localStorage.setItem("price", 475);
            localStorage.setItem("scf_price", 70);
            localStorage.setItem("plan_type", 2);

        }
        if (name == 'platinum') {

            this.setState({
                selectedPlan: name,
                price: 650,
                scf_price: 65
            });

            localStorage.setItem("selectedPlan", name);
            localStorage.setItem("price", 650);
            localStorage.setItem("scf_price", 65);
            localStorage.setItem("plan_type", 3);


        }

    }

    onAddCoverage = (e, price, id) => {
        var data = { 'keyName': e.currentTarget.name, 'keyPrice': "$" + price };
        const additional_coverage = [...this.state.additional_coverage, data];
        var flag = this.state.flag;
        this.state.addons.push(id);
        flag[e.currentTarget.value] = !this.state.flag[e.currentTarget.value];
        this.setState({ additional_coverage, flag });

        this.setState({ totalPrice: this.state.totalPrice + parseFloat(price) });
        localStorage.setItem("addons", this.state.addons.join());
    }

    onRemoveCoverage = (e, price, id) => {
        var index = this.state.addons.indexOf(id);
        this.state.addons.splice(index, 1);
        localStorage.setItem("addons", this.state.addons.join());
        const additional_coverage = this.state.additional_coverage.filter((item, i) => {
            if (e.currentTarget.name == item.keyName) {
                this.setState({ totalPrice: this.state.totalPrice - parseFloat(price) });
            }
            return e.currentTarget.name !== item.keyName
        });
        var flag = this.state.flag;
        flag[e.currentTarget.value] = !this.state.flag[e.currentTarget.value];
        this.setState({ additional_coverage, flag });

        // this.setState({additional_coverage: this.state.additional_coverage.push(e.currentTarget.name)})
    }

    checkIfPlanSelected = e => {
        if (this.state.selectedPlan === '') {
            e.preventDefault();
            alert("Please select Plan");
            // this.state.error = true;
        }
        var total = this.state.selectedPlan != '' ? (this.state.totalPrice + getPrice(this.state.selectedPlan)) : this.state.totalPrice;
        var tax = calculateTax(this.state.selectedPlan != '' ? (this.state.totalPrice + getPrice(this.state.selectedPlan)) : this.state.totalPrice);
        localStorage.setItem("total", total + parseFloat(tax));
        localStorage.setItem("tax", tax);
    }

    btnChevronHandle = (e, tickleClass, collapseId) => {
        //this.setState({ standardChevronDown: !this.state.standardChevronDown });
        var element = document.getElementsByClassName(tickleClass);
        var collpased = document.getElementById(collapseId);
        if (element[0].classList.contains("fa-chevron-down")) {
            element[0].classList.remove("fa-chevron-down");
            element[0].classList.add("fa-chevron-up");
            collpased.className = "collapse show";
        }
        else {
            element[0].classList.remove("fa-chevron-up");
            element[0].classList.add("fa-chevron-down");
            collpased.className = "collapse";
        }
    }
    render() {
        const { plans } = this.state;
        const { selectedPlan } = this.state;
        const { additional_coverage } = this.state;
        const { error } = this.state;

        return (
            <div>
                <section class="price-sub-header inner-banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <h2> Pricing & Plans</h2>
                                <p className="mt-3" style={{ textAlign: 'left' }}><Link to='/home' className="breadcrumb-text-css">Home</Link><span><i class="fas fa-angle-double-right"></i></span><span> Pricing & Plans</span></p>
                            </div>
                            <div class="subbanner-phone banner-phone-web"><a href="tel:7133143636">
                                <i class="fas fa-phone-alt"></i>(713) 314-3636</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="price-table">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7 mt-5 mb-0 mb-lg-5">
                                <div class="price-left p-4">
                                    <h2 class="text-center sub-h pt-4  mb-5 mb-lg-5">Stalwart Home Warranty Pricing & Plans</h2>
                                    <table class="table table-bordered table-responsive web-price-table">
                                        <tbody>
                                            <tr>
                                                <td rowSpan="3">
                                                    <p class="table-h">Select the packtage that  best suits you need</p>
                                                    <p>
                                                        <span class="table-subh">Single Family Home/ Condo/ Townhome/ Mobile home</span>
                                                    </p></td>
                                                <td class={this.state.selectedPlan === 'standard' ? "color-blue fw-bold text-center price-active" : "color-blue fw-bold text-center"}>STANDARD COVERAGE</td>
                                                <td class={this.state.selectedPlan === 'gold' ? "color-blue fw-bold text-center price-active" : "color-blue fw-bold text-center"}>GOLD COVERAGE</td>
                                                <td class={this.state.selectedPlan === 'platinum' ? "color-blue fw-bold text-center price-active" : "color-blue fw-bold text-center"}>PLATINUM COVERAGE </td>
                                            </tr>
                                            <tr lass="text-center">
                                                <td><span class="text-center d-block table-price fw-bold"><span class="small"> $</span> 375</span><small class="text-center d-block"><strong>SCF $75</strong></small></td>
                                                <td><span class="text-center d-block table-price fw-bold"><span class="small"> $</span> 475</span><small class="text-center d-block"><strong>SCF $70</strong></small></td>
                                                <td><span class="text-center d-block table-price fw-bold"><span class="small"> $</span> 650</span><small class="text-center d-block"><strong>SCF $65</strong></small></td>
                                            </tr>
                                            <tr class="text-center">
                                                <td><button class={this.state.selectedPlan === 'standard' ? "btn price-select-btn price-active" : "btn price-select-btn"} onClick={e => this.onPlanChanged('standard')}>{this.state.selectedPlan === 'standard' ? 'Selected' : 'Select'}</button></td>
                                                <td><button class={this.state.selectedPlan === 'gold' ? "btn price-select-btn price-active" : "btn price-select-btn"} onClick={e => this.onPlanChanged('gold')} >{this.state.selectedPlan === 'gold' ? 'Selected' : 'Select'}</button></td>
                                                <td><button class={this.state.selectedPlan === 'platinum' ? "btn price-select-btn price-active" : "btn price-select-btn"} onClick={e => this.onPlanChanged('platinum')} >{this.state.selectedPlan === 'platinum' ? 'Selected' : 'Select'}</button></td>
                                            </tr>
                                            <AddColumn isMobile="false" coveredPlan="Primary Gas, Oil or Electric Heater" />
                                            <AddColumn isMobile="false" coveredPlan="Air Conditioner (Two Systems per coverage)" />
                                            <AddColumn isMobile="false" coveredPlan="Duct Work" />
                                            <AddColumn isMobile="false" coveredPlan="Limited Pest Control" />
                                            <AddColumn isMobile="false" coveredPlan="Drain Line Stoppages" />
                                            <AddColumn isMobile="false" coveredPlan="Plumbing Pipe Leaks (including Polybutylene)" />
                                            <AddColumn isMobile="false" coveredPlan="Toilets" />
                                            <AddColumn isMobile="false" coveredPlan="Water Heater (50 gallon) or tankless(Two units per coverage)" />
                                            <AddColumn isMobile="false" coveredPlan="Built-in Jetted Bathtub Motor & Pump" />
                                            <AddColumn isMobile="false" coveredPlan="Recirculating Pump" />
                                            <AddColumn isMobile="false" coveredPlan="Instant Hot/Cold Water Dispenser" />
                                            <AddColumn isMobile="false" coveredPlan="Garbage Disposal" />
                                            <AddColumn isMobile="false" coveredPlan="Water Pressure Regulator" />
                                            <AddColumn isMobile="false" coveredPlan="Sump Pump" />
                                            <AddColumn isMobile="false" coveredPlan="Electrical" />
                                            <AddColumn isMobile="false" coveredPlan="Exhaust, Attic, Ceiling, Whole House Fans" />
                                            <AddColumn isMobile="false" coveredPlan="Garage Door Opener (Two Units)" />
                                            <AddColumn isMobile="false" coveredPlan="Central Vacuum" />
                                            <AddColumn isMobile="false" coveredPlan="Rekey Service (Two Keys)" />
                                            <AddColumn isMobile="false" coveredPlan="Dishwasher" />
                                            <AddColumn isMobile="false" coveredPlan="Transh Compactor" />
                                            <AddColumn isMobile="false" coveredPlan="Kithen Exhaust Fan" />
                                            <AddColumn isMobile="false" coveredPlan="Oven / Range / Cooktop" />
                                            <AddColumn isMobile="false" coveredPlan="Built-in Microwave" />

                                            <AddColumngold isMobile="false" coveredPlan="Refrigerant Reclaim and Recapture" />
                                            <AddColumngold isMobile="false" coveredPlan="Permits, Haul Away and Disposal" />
                                            <AddColumngold isMobile="false" coveredPlan="Mismatched Systems (HVAC)" />

                                            <AddColumnplatinum isMobile="false" coveredPlan="Increased Coverage for Plumbing items / Stoppages" />
                                            <AddColumnplatinum isMobile="false" coveredPlan="Increased Coverage for Manufacturer's Warranty" />
                                            <AddColumnplatinum isMobile="false" coveredPlan="Increased Coverage for Improper installation (HVAC Systems)" />
                                        </tbody>
                                    </table>
                                    <div class="accordion list-price-mob-main" id="list-price-mob">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <button class="btn btn-link d-block btnprice collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={e => this.btnChevronHandle(e, "tickle1", "collapseOne")}>
                                                    STANDARD COVERAGE
                                                    <i class='fas tickle tickle1 float-right fa-chevron-down'></i>
                                                </button>

                                            </div>
                                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#list-price-mob">
                                                    <div class="pt-4 pr-4 pl-4">
                                                        <h4 class="color-blue">Let's choose the plan that fits your need</h4>
                                                        <p>All plan have SCF $75 service call free payable online or to the technician at the initation of aclaim</p>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="border">
                                                            <h4 class="text-center card-body-h">STANDARD COVERAGE</h4>
                                                            <span class="text-center d-block table-price fw-bold"><span class="small"> $</span>375</span><small class="text-center d-block"><strong>SCF $75</strong></small>
                                                        <button class={this.state.selectedPlan === 'standard' ? "btn price-select-btn d-block mb-2 mt-3 price-active" : "btn price-select-btn d-block mb-2 mt-3"}
                                                            onClick={e => this.onPlanChanged('standard')}>
                                                        {this.state.selectedPlan === 'standard' ? 'Selected' : 'Select'}
                                                            
                                                        </button>
                                                            <table class="table table-bordered mb-0">
                                                                <tbody>
                                                                    <AddColumn isMobile="true" coveredPlan="Primary Gas, Oil or Electric Heater" />
                                                                    <AddColumn isMobile="true" coveredPlan="Air Conditioner (Two Systems per coverage)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Duct Work" />
                                                                    <AddColumn isMobile="true" coveredPlan="Limited Pest Control" />
                                                                    <AddColumn isMobile="true" coveredPlan="Drain Line Stoppages" />
                                                                    <AddColumn isMobile="true" coveredPlan="Plumbing Pipe Leaks (including Polybutylene)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Toilets" />
                                                                    <AddColumn isMobile="true" coveredPlan="Water Heater (50 gallon) or tankless(Two units per coverage)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Built-in Jetted Bathtub Motor & Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Recirculating Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Instant Hot/Cold Water Dispenser" />
                                                                    <AddColumn isMobile="true" coveredPlan="Garbage Disposal" />
                                                                    <AddColumn isMobile="true" coveredPlan="Water Pressure Regulator" />
                                                                    <AddColumn isMobile="true" coveredPlan="Sump Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Electrical" />
                                                                    <AddColumn isMobile="true" coveredPlan="Exhaust, Attic, Ceiling, Whole House Fans" />
                                                                    <AddColumn isMobile="true" coveredPlan="Garage Door Opener (Two Units)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Central Vacuum" />
                                                                    <AddColumn isMobile="true" coveredPlan="Rekey Service (Two Keys)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Dishwasher" />
                                                                    <AddColumn isMobile="true" coveredPlan="Transh Compactor" />
                                                                    <AddColumn isMobile="true" coveredPlan="Kithen Exhaust Fan" />
                                                                    <AddColumn isMobile="true" coveredPlan="Oven / Range / Cooktop" />
                                                                    <AddColumn isMobile="true" coveredPlan="Built-in Microwave" />

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="price-right p-4">
                                                        <h2 class="text-center sub-h pt-4  mb-5"> Additional Coverages</h2>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="additional-list" style={!this.state.flag.one ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Pre-Season HVAC Tune-up" subheading="" flag={this.state.flag.one} coverage="one" price="25" onAddCoverage={(e) => this.onAddCoverage(e, "25", 1)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "25", 1)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.two ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Swimming Pool / Spa Equipment" subheading="in-ground only" flag={this.state.flag.two} coverage="two" price="180" onAddCoverage={(e) => this.onAddCoverage(e, "180", 2)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "180", 2)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.three ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Washer / Dryer(set)" subheading="in-ground only" flag={this.state.flag.three} coverage="three" price="90" onAddCoverage={(e) => this.onAddCoverage(e, "90", 3)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "90", 3)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.four ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Additional HVAC Units" subheading="in-ground only" flag={this.state.flag.four} coverage="four" price="50" onAddCoverage={(e) => this.onAddCoverage(e, "50", 4)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "50", 4)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.five ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Well Pump" subheading="in-ground only" flag={this.state.flag.five} coverage="five" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 5)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 5)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.six ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Septic Tank Pumping / Septic System / Sewage Ejector Pump" subheading="in-ground only" flag={this.state.flag.six} coverage="six" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 6)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 6)} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 pl-3 ">
                                                                <div class="additional-list" style={!this.state.flag.seven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Outdoor Kitchen" subheading="Built in natural gas only" flag={this.state.flag.seven} coverage="seven" price="120" onAddCoverage={(e) => this.onAddCoverage(e, "120", 7)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "120", 7)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eight ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Limited Roof Leak Repair" subheading="" flag={this.state.flag.eight} coverage="eight" price="95" onAddCoverage={(e) => this.onAddCoverage(e, "95", 8)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "95", 8)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.nine ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Kitchen Refrigerator" subheading="in-ground only" flag={this.state.flag.nine} coverage="nine" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 9)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 9)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.ten ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Water softener / Reverse Osmosis/whole house filtration" subheading="in-ground only" flag={this.state.flag.ten} coverage="ten" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 10)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 10)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eleven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Booster Pump" subheading="in-ground only" flag={this.state.flag.eleven} coverage="eleven" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 11)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 11)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed btnprice" type="button" data-toggle="collapse"
                                                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                                        onClick={e => this.btnChevronHandle(e, "tickle2", "collapseTwo")}>
                                                       
                                                        GOLD COVERAGE
                                                        <i class='fas tickle tickle2 float-right fa-chevron-down' ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                                <div id="collapseTwo" class= "collapse" aria-labelledby="headingTwo" data-parent="#list-price-mob">
                                                    <div class="pt-4 pr-4 pl-4">
                                                        <h4 class="color-blue">Let's choose the plan that fits your need</h4>
                                                        <p>All plan have SCF $75 service call free payable online or to the technician at the initation of aclaim</p>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="border">
                                                            <h4 class="text-center card-body-h">GOLD COVERAGE</h4>
                                                            <span class="text-center d-block table-price fw-bold"><span class="small"> $</span>475</span><small class="text-center d-block"><strong>SCF $70</strong></small>
                                                        <button class={this.state.selectedPlan === 'gold' ? "btn price-select-btn d-block mb-2 mt-3 price-active" : "btn price-select-btn d-block mb-2 mt-3"}
                                                            onClick={e => this.onPlanChanged('gold')}>
                                                            {this.state.selectedPlan === 'gold' ? 'Selected' : 'Select'}
                                                        </button>

                                                        <table class="table table-bordered mb-0">
                                                                <tbody>
                                                                    <AddColumn isMobile="true" coveredPlan="Primary Gas, Oil or Electric Heater" />
                                                                    <AddColumn isMobile="true" coveredPlan="Air Conditioner (Two Systems per coverage)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Duct Work" />
                                                                    <AddColumn isMobile="true" coveredPlan="Limited Pest Control" />
                                                                    <AddColumn isMobile="true" coveredPlan="Drain Line Stoppages" />
                                                                    <AddColumn isMobile="true" coveredPlan="Plumbing Pipe Leaks (including Polybutylene)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Toilets" />
                                                                    <AddColumn isMobile="true" coveredPlan="Water Heater (50 gallon) or tankless(Two units per coverage)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Built-in Jetted Bathtub Motor & Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Recirculating Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Instant Hot/Cold Water Dispenser" />
                                                                    <AddColumn isMobile="true" coveredPlan="Garbage Disposal" />
                                                                    <AddColumn isMobile="true" coveredPlan="Water Pressure Regulator" />
                                                                    <AddColumn isMobile="true" coveredPlan="Sump Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Electrical" />
                                                                    <AddColumn isMobile="true" coveredPlan="Exhaust, Attic, Ceiling, Whole House Fans" />
                                                                    <AddColumn isMobile="true" coveredPlan="Garage Door Opener (Two Units)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Central Vacuum" />
                                                                    <AddColumn isMobile="true" coveredPlan="Rekey Service (Two Keys)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Dishwasher" />
                                                                    <AddColumn isMobile="true" coveredPlan="Transh Compactor" />
                                                                    <AddColumn isMobile="true" coveredPlan="Kithen Exhaust Fan" />
                                                                    <AddColumn isMobile="true" coveredPlan="Oven / Range / Cooktop" />
                                                                    <AddColumn isMobile="true" coveredPlan="Built-in Microwave" />
                                                                    <AddColumngold isMobile="true" coveredPlan="Refrigerant Reclaim and Recapture" />
                                                                    <AddColumngold isMobile="true" coveredPlan="Permits, Haul Away and Disposal" />
                                                                    <AddColumngold isMobile="true" coveredPlan="Mismatched Systems (HVAC)" />
                                                                    <AddColumnplatinum isMobile="true" coveredPlan="Increased Coverage for Plumbing items / Stoppages" />
                                                                    <AddColumnplatinum isMobile="true" coveredPlan="Increased Coverage for Manufacturer's Warranty" />
                                                                    <AddColumnplatinum isMobile="true" coveredPlan="Increased Coverage for Improper installation (HVAC Systems)" />

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="price-right p-4">
                                                        <h2 class="text-center sub-h pt-4  mb-5"> Additional Coverages</h2>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="additional-list" style={!this.state.flag.one ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Pre-Season HVAC Tune-up" subheading="" flag={this.state.flag.one} coverage="one" price="25" onAddCoverage={(e) => this.onAddCoverage(e, "25", 1)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "25", 1)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.two ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Swimming Pool / Spa Equipment" subheading="in-ground only" flag={this.state.flag.two} coverage="two" price="180" onAddCoverage={(e) => this.onAddCoverage(e, "180", 2)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "180", 2)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.three ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Washer / Dryer(set)" subheading="in-ground only" flag={this.state.flag.three} coverage="three" price="90" onAddCoverage={(e) => this.onAddCoverage(e, "90", 3)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "90", 3)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.four ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Additional HVAC Units" subheading="in-ground only" flag={this.state.flag.four} coverage="four" price="50" onAddCoverage={(e) => this.onAddCoverage(e, "50", 4)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "50", 4)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.five ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Well Pump" subheading="in-ground only" flag={this.state.flag.five} coverage="five" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 5)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 5)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.six ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Septic Tank Pumping / Septic System / Sewage Ejector Pump" subheading="in-ground only" flag={this.state.flag.six} coverage="six" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 6)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 6)} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 pl-3 ">
                                                                <div class="additional-list" style={!this.state.flag.seven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Outdoor Kitchen" subheading="Built in natural gas only" flag={this.state.flag.seven} coverage="seven" price="120" onAddCoverage={(e) => this.onAddCoverage(e, "120", 7)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "120", 7)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eight ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Limited Roof Leak Repair" subheading="" flag={this.state.flag.eight} coverage="eight" price="95" onAddCoverage={(e) => this.onAddCoverage(e, "95", 8)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "95", 8)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.nine ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Kitchen Refrigerator" subheading="in-ground only" flag={this.state.flag.nine} coverage="nine" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 9)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 9)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.ten ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Water softener / Reverse Osmosis/whole house filtration" subheading="in-ground only" flag={this.state.flag.ten} coverage="ten" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 10)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 10)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eleven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Booster Pump" subheading="in-ground only" flag={this.state.flag.eleven} coverage="eleven" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 11)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 11)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed btnprice" type="button" data-toggle="collapse"
                                                        data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                                                        onClick={e => this.btnChevronHandle(e, "tickle3", "collapseThree")}>
                                                        PLATINUM COVERAGE
                                                        <i class='fas tickle tickle3 float-right fa-chevron-down'></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" class= "collapse" aria-labelledby="headingThree" data-parent="#list-price-mob">
                                                    <div class="pt-4 pr-4 pl-4">
                                                        <h4 class="color-blue">Let's choose the plan that fits your need</h4>
                                                        <p>All plan have SCF $75 service call free payable online or to the technician at the initation of aclaim</p>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="border">
                                                            <h4 class="text-center card-body-h">PLATINUM COVERAGE </h4>
                                                            <span class="text-center d-block table-price fw-bold"><span class="small"> $</span>650</span><small class="text-center d-block"><strong>SCF $65</strong></small>
                                                        <button class={this.state.selectedPlan === 'platinum' ? "btn price-select-btn d-block mb-2 mt-3 price-active" : "btn price-select-btn d-block mb-2 mt-3"}
                                                            onClick={e => this.onPlanChanged('platinum')}>
                                                            {this.state.selectedPlan === 'platinum' ? 'Selected' : 'Select'}
                                                        </button>
                                                        <table class="table table-bordered mb-0">
                                                            

                                                            <tbody>
                                                                    <AddColumn isMobile="true" coveredPlan="Primary Gas, Oil or Electric Heater" />
                                                                    <AddColumn isMobile="true" coveredPlan="Air Conditioner (Two Systems per coverage)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Duct Work" />
                                                                    <AddColumn isMobile="true" coveredPlan="Limited Pest Control" />
                                                                    <AddColumn isMobile="true" coveredPlan="Drain Line Stoppages" />
                                                                    <AddColumn isMobile="true" coveredPlan="Plumbing Pipe Leaks (including Polybutylene)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Toilets" />
                                                                    <AddColumn isMobile="true" coveredPlan="Water Heater (50 gallon) or tankless(Two units per coverage)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Built-in Jetted Bathtub Motor & Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Recirculating Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Instant Hot/Cold Water Dispenser" />
                                                                    <AddColumn isMobile="true" coveredPlan="Garbage Disposal" />
                                                                    <AddColumn isMobile="true" coveredPlan="Water Pressure Regulator" />
                                                                    <AddColumn isMobile="true" coveredPlan="Sump Pump" />
                                                                    <AddColumn isMobile="true" coveredPlan="Electrical" />
                                                                    <AddColumn isMobile="true" coveredPlan="Exhaust, Attic, Ceiling, Whole House Fans" />
                                                                    <AddColumn isMobile="true" coveredPlan="Garage Door Opener (Two Units)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Central Vacuum" />
                                                                    <AddColumn isMobile="true" coveredPlan="Rekey Service (Two Keys)" />
                                                                    <AddColumn isMobile="true" coveredPlan="Dishwasher" />
                                                                    <AddColumn isMobile="true" coveredPlan="Transh Compactor" />
                                                                    <AddColumn isMobile="true" coveredPlan="Kithen Exhaust Fan" />
                                                                    <AddColumn isMobile="true" coveredPlan="Oven / Range / Cooktop" />
                                                                    <AddColumn isMobile="true" coveredPlan="Built-in Microwave" />
                                                                    <AddColumngold isMobile="true" coveredPlan="Refrigerant Reclaim and Recapture" />
                                                                    <AddColumngold isMobile="true" coveredPlan="Permits, Haul Away and Disposal" />
                                                                    <AddColumngold isMobile="true" coveredPlan="Mismatched Systems (HVAC)" />

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="price-right p-4">
                                                        <h2 class="text-center sub-h pt-4  mb-5"> Additional Coverages</h2>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="additional-list" style={!this.state.flag.one ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Pre-Season HVAC Tune-up" subheading="" flag={this.state.flag.one} coverage="one" price="25" onAddCoverage={(e) => this.onAddCoverage(e, "25", 1)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "25", 1)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.two ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Swimming Pool / Spa Equipment" subheading="in-ground only" flag={this.state.flag.two} coverage="two" price="180" onAddCoverage={(e) => this.onAddCoverage(e, "180", 2)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "180", 2)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.three ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Washer / Dryer(set)" subheading="in-ground only" flag={this.state.flag.three} coverage="three" price="90" onAddCoverage={(e) => this.onAddCoverage(e, "90", 3)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "90", 3)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.four ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Additional HVAC Units" subheading="in-ground only" flag={this.state.flag.four} coverage="four" price="50" onAddCoverage={(e) => this.onAddCoverage(e, "50", 4)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "50", 4)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.five ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Well Pump" subheading="in-ground only" flag={this.state.flag.five} coverage="five" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 5)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 5)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.six ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Septic Tank Pumping / Septic System / Sewage Ejector Pump" subheading="in-ground only" flag={this.state.flag.six} coverage="six" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 6)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 6)} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 pl-3 ">
                                                                <div class="additional-list" style={!this.state.flag.seven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Outdoor Kitchen" subheading="Built in natural gas only" flag={this.state.flag.seven} coverage="seven" price="120" onAddCoverage={(e) => this.onAddCoverage(e, "120", 7)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "120", 7)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eight ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Limited Roof Leak Repair" subheading="" flag={this.state.flag.eight} coverage="eight" price="95" onAddCoverage={(e) => this.onAddCoverage(e, "95", 8)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "95", 8)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.nine ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Kitchen Refrigerator" subheading="in-ground only" flag={this.state.flag.nine} coverage="nine" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 9)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 9)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.ten ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Water softener / Reverse Osmosis/whole house filtration" subheading="in-ground only" flag={this.state.flag.ten} coverage="ten" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 10)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 10)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eleven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Booster Pump" subheading="in-ground only" flag={this.state.flag.eleven} coverage="eleven" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 11)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 11)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                                                <div class="col-lg-5 mt-5 mb-5 bg-white">
                                                    <div class="price-right price-right-web p-4">
                                                        <h2 class="text-center sub-h pt-4  mb-5"> Additional Coverages</h2>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="additional-list" style={!this.state.flag.one ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Pre-Season HVAC Tune-up" subheading="" flag={this.state.flag.one} coverage="one" price="25" onAddCoverage={(e) => this.onAddCoverage(e, "25", 1)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "25", 1)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.two ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Swimming Pool / Spa Equipment" subheading="in-ground only" flag={this.state.flag.two} coverage="two" price="180" onAddCoverage={(e) => this.onAddCoverage(e, "180", 2)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "180", 2)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.three ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Washer / Dryer(set)" subheading="in-ground only" flag={this.state.flag.three} coverage="three" price="90" onAddCoverage={(e) => this.onAddCoverage(e, "90", 3)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "90", 3)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.four ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Additional HVAC Units" subheading="in-ground only" flag={this.state.flag.four} coverage="four" price="50" onAddCoverage={(e) => this.onAddCoverage(e, "50", 4)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "50", 4)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.five ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Well Pump" subheading="in-ground only" flag={this.state.flag.five} coverage="five" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 5)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 5)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.six ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Septic Tank Pumping / Septic System / Sewage Ejector Pump" subheading="in-ground only" flag={this.state.flag.six} coverage="six" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 6)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 6)} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 pl-3 ">
                                                                <div class="additional-list" style={!this.state.flag.seven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Outdoor Kitchen" subheading="Built in natural gas only" flag={this.state.flag.seven} coverage="seven" price="120" onAddCoverage={(e) => this.onAddCoverage(e, "120", 7)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "120", 7)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eight ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Limited Roof Leak Repair" subheading="" flag={this.state.flag.eight} coverage="eight" price="95" onAddCoverage={(e) => this.onAddCoverage(e, "95", 8)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "95", 8)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.nine ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Kitchen Refrigerator" subheading="in-ground only" flag={this.state.flag.nine} coverage="nine" price="100" onAddCoverage={(e) => this.onAddCoverage(e, "100", 9)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "100", 9)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.ten ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Water softener / Reverse Osmosis/whole house filtration" subheading="in-ground only" flag={this.state.flag.ten} coverage="ten" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 10)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 10)} />
                                                                </div>
                                                                <div class="additional-list" style={!this.state.flag.eleven ? { border: '3px solid #1d2251' } : { border: '' }}>
                                                                    <AddButton heading="Booster Pump" subheading="in-ground only" flag={this.state.flag.eleven} coverage="eleven" price="75" onAddCoverage={(e) => this.onAddCoverage(e, "75", 11)} onRemoveCoverage={(e) => this.onRemoveCoverage(e, "75", 11)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="price-right pb-5 mt-0 mt-lg-5">
                                                        <h2 class="text-center sub-h pt-4 mt-0 mt-lg-5"> Total Price</h2>
                                                        <div class="p-3">
                                                            <table class="table table-bordered">
                                                                <tr>
                                                                    <th class="bg-blue">Selected Plan + Additional coverage</th>
                                                                    <th class="bg-blue">Price</th>
                                                                </tr>
                                                                {this.state.selectedPlan != '' && <tr>
                                                                    <td>{this.state.selectedPlan}</td>
                                                                    <td>${getPrice(this.state.selectedPlan)}</td>
                                                                </tr>}
                                                                {this.state.additional_coverage.map((item, i) => {
                                                                    return (<tr key="i">
                                                                        <td>{item.keyName}</td>
                                                                        <td>{item.keyPrice}</td>
                                                                    </tr>)
                                                                })}
                                                                <tr>
                                                                    <td>Tax</td>
                                                                    <td>${calculateTax(this.state.selectedPlan != '' ? (this.state.totalPrice + getPrice(this.state.selectedPlan)) : this.state.totalPrice)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Total</b></td>
                                                                    <td><b>${this.state.selectedPlan != '' ? (this.state.totalPrice + getPrice(this.state.selectedPlan) + parseFloat(calculateTax(this.state.totalPrice + getPrice(this.state.selectedPlan)))) : (this.state.totalPrice + parseFloat(calculateTax(this.state.totalPrice)))}</b></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-12 text-center mb-5">
                                                    <div class="btn-group text-center pricing-group-btn" role="group" aria-label="Basic example">
                                                        <Link to='/home' className="pricing-group-btn1">&laquo; Back</Link>
                                                        <Link className="pricing-group-btn2" to={{
                                                            pathname: '/details',
                                                            plan: this.state.selectedPlan
                                                        }} onClick={this.checkIfPlanSelected}>Next &raquo;</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                </section>
                <footer class="footer-section">
                                        <div class="container">
                                            <div className="row" style={{ justifyContent: 'center' }}>
                                                <div class="footer-col-1">
                                                    <h2 class="mt-0">Stalwart Home Warranty</h2>
                                                </div>

                                            </div>
                                            <hr style={{ background: '#fff', marginBottom: '30px' }} />
                                            <div className="row">
                                                <div class="col-lg-8 footer-col-3">
                                                    <h2 >Stalwart Home Warranty</h2>
                                                    <p style={{ marginTop: '-25px' }}>stalwarthomewarranty@gmail.com</p>
                                                    <p>© 2020 - Stalwart Home Warranty,<br /> All Right Reserved</p>
                                                </div>
                                                <div class="col-lg-4 ml-auto mb-5 mb-lg-0 footer-col-4">
                                                    <div class="media">
                                                        <i class="fas fa-phone-alt fa-3x mb-3"></i>
                                                        <div class="media-body pl-4">
                                                            <h3>CALL US NOW !</h3>
                                                            <h4>(713) 314-3636</h4>

                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>

                                    </footer>
             </div>
        );
    }
}

export default withRouter(Planandpricing);