import React, { Component } from 'react'
import { Button, Form, FormGroup, FormControl, Row, Col, FormLabel, Modal } from "react-bootstrap";
import '../styles/home.css';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { ApiUrl } from '../constants/api';
import { getAddon } from '../constants/data';

import fetchHeaders from '../helpers/fetch';
import { Loader } from 'react-overlay-loader';
import '../styles/dataTable.css';

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: {},
            calls: {},
            packages: {},
            addons: {},
            checks: 0
        }
    }

    fetch_report_list() {
        var that = this;
        fetch(ApiUrl() + '/report/list.php', {
            headers: fetchHeaders()
        })
            .then(response => response.json())
            .then(function (res) {
                if (!res.status) {
                    that.setState({ users: null, errorMessage: res.message, loading: false });
                }
                else {
                    that.setState({ clients: res.clients, calls: res.calls, packages: res.packages, addons: res.addons, checks: res.checkTotal, loading: false, errorMessage: '' });
                }
            });
    }


    componentDidMount() {
        this.fetch_report_list();
    }

    render() {

        return (
            <div>
                <div className="font-color container">
                    <div className="row">
                        <div className="col-sm-4 mt-5 text-left stal"><strong>SHW Overview </strong></div>
                    </div>
                </div>
                <div className="row col-sm-12 text-center active-cyan-4 mb-4 mt-1">
                    <table className="col-sm-5 table table-bordered ml-3">

                        <thead>
                            <tr id="header">
                                <th className="choose" colspan="2">Clients</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td >Total Clients</td>
                                <td class="w-25">{this.state.clients.total}</td>
                            </tr>
                            <tr>
                                <td >Active</td>
                                <td class="w-25">{this.state.clients.Active}</td>
                            </tr>
                            <tr>
                                <td >In-Active</td>
                                <td class="w-25">{this.state.clients.InActive}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="col-sm-5 table table-bordered ml-3 ">
                        <thead>
                            <tr id="header">
                                <th className="choose" colspan="2">Calls/Tickets</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Total Calls</td>
                                <td class="w-25">{this.state.calls.total}</td>
                            </tr>
                            <tr>
                                <td>Active</td>
                                <td class="w-25">{this.state.calls.Active}</td>
                            </tr>
                            <tr>
                                <td>In-Active</td>
                                <td class="w-25">{this.state.calls.InActive}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row col-sm-12 text-center active-cyan-4 mb-4 mt-1">
                    <table className="col-sm-5 table table-bordered ml-3 ">
                        <thead>
                            <tr id="header">
                                <th className="choose" colspan="2">Addons</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(11)].map((x, i) =>
                                <tr>
                                    <td >{getAddon(i + 1)}</td>
                                    <td class="w-25">{this.state.addons[i + 1]}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="col-sm-5">
                        <table className="col-sm-12 table table-bordered ml-3 ">
                            <thead>
                                <tr id="header">
                                    <th className="choose" colspan="2">Packages</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total</td>
                                    <td class="w-25">{this.state.packages.total}</td>
                                </tr>
                                <tr>
                                    <td>Standard</td>
                                    <td class="w-25">{this.state.packages.Standard}</td>
                                </tr>
                                <tr>
                                    <td>Gold</td>
                                    <td class="w-25">{this.state.packages.Gold}</td>
                                </tr>
                                <tr>
                                    <td>Platinum</td>
                                    <td>{this.state.packages.Platinum}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="col-sm-12 table table-bordered ml-3 ">
                            <thead>
                                <tr id="header">
                                    <th className="choose" colspan="2">Checks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total</td>
                                    <td class="w-25">{this.state.checks}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}
export default withRouter(Reports);