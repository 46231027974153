class Auth {
    constructor() {
        this.authenticated = false
    }

    login() {
        if (sessionStorage.token) {
            this.authenticated = true
            // next()
        } else {
            this.authenticated = false
            // next()
        }

    }

    logout() {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('ur')
        sessionStorage.removeItem('uid')
        sessionStorage.removeItem('email')
        this.authenticated = false
        // next()
    }

    isAuthenticated() {
        if (sessionStorage.token) {
            this.authenticated = true
            // next()
        } else {
            this.authenticated = false
            // next()
        }
        return this.authenticated
    }
}

export default new Auth()